import type {
  CustomPost,
  CustomPost2,
  Endpoints,
  Post,
  PostWithRoute,
  WithRoute,
} from '~/types'

export const useMediasStore = defineStore('mediasStore', () => {
  const socialStore = useSocialStore()

  // STATE
  const medias = ref<Post[]>([])
  const media = ref<WithRoute<CustomPost2>>()
  const mediasPages = ref(1)
  const mediasCurrentPage = ref(1)
  const userMedias = ref<PostWithRoute[]>([])
  const otherMedias = ref<WithRoute<CustomPost>[]>([])
  const bannedIds = ref<number[]>([])
  const communityMedias = ref<Post[]>([])
  const communityMediasPages = ref(1)
  const communityMediasCurrentPage = ref(1)

  // GETTERS
  const getBannedIds = computed(() => bannedIds.value)
  const getMediaId = computed(() => media.value?.id)
  const getMediaCommunity = computed(() => media.value?.community?.slug)
  const getPagesCount = computed(() => mediasPages.value)
  const getCurrentPage = computed(() => mediasCurrentPage.value)
  const getCommunityPagesCount = computed(() => communityMediasPages.value)
  const getCommunityCurrentPage = computed(
    () => communityMediasCurrentPage.value,
  )

  // ACTIONS
  const addContent = (content: PostWithRoute) => {
    content.route = `/communautes/${content.community.slug}/avis-medias/${content.slug}`
    medias.value.push(content)
  }

  const fetchMedias = async ({
    page = 1,
    search,
  }: {
    page?: number
    search?: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          q: search,
          category_slug: 'avis-medias',
          banned_ids: getBannedIds.value,
          page,
          sort: 'date',
          limit: 36,
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      medias.value = []

      return logError(
        'from actions',
        'There was a problem fetching medias: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community.slug}/avis-medias/${element.slug}`,
    }))

    medias.value = formattedData
    mediasPages.value = rawData.pages_count
    mediasCurrentPage.value = page
  }

  const fetchUserMedias = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SECURE_POST_COMMUNITY']
    >(API.GET__SECURE_POST_COMMUNITY, {
      query: {
        category_slug: 'avis-medias',
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      userMedias.value = []
      return logError(
        'from actions',
        'There was a problem fetching user medias: ',
        error,
      )
    }

    const postIds: number[] = []
    const formattedData: PostWithRoute[] = []

    rawData.forEach((element) => {
      if (element.id) postIds.push(element.id)
      formattedData.push({
        ...element,
        route: `/communautes/${element.community.slug}/avis-medias/${element.slug}`,
      })
    })

    userMedias.value = formattedData
    bannedIds.value = postIds
  }

  const fetchCommunityMedias = async ({
    page = 1,
    community,
    search,
  }: {
    page?: number
    community?: string
    search?: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          q: search,
          category_slug: 'avis-medias',
          community_slug: community,
          page,
          sort: 'date',
          limit: 36,
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      communityMedias.value = []
      return logError(
        'from actions',
        'There was a problem fetching medias',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community.slug}/avis-medias/${element.slug}`,
    }))

    communityMedias.value = formattedData
    communityMediasPages.value = rawData.pages_count
    communityMediasCurrentPage.value = page
  }

  const fetchOtherMedias = async () => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          banned_ids: getMediaId.value,
          category_slug: 'avis-medias',
          community_slug: getMediaCommunity.value,
          limit: '12',
          page: 1,
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      otherMedias.value = []

      return logError(
        'from actions',
        'There was a problem fetching other medias: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community.slug}/${element.category.slug}/${element.slug}`,
    }))
    otherMedias.value = formattedData
  }

  const fetchMedia = async ({
    slug,
    community,
    category,
  }: {
    slug: string
    community: string
    category: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST']>(
      `${API.GET__POST}/${slug}`,
      {
        query: {
          community_slug: community,
          category_slug: category,
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      media.value = undefined
      return logError(
        'from actions',
        'There was a problem fetching media: ',
        error,
      )
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    const formattedData = {
      ...rawData,
      route: `/communautes/${rawData.community?.slug}/${rawData.category.slug}/${rawData.slug}`,
    }

    socialStore.fetchSocial({
      slug,
      slugType: 'avis-medias',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
      is_following: rawData.is_following ?? false,
    })

    media.value = formattedData
    await fetchOtherMedias()
  }

  return {
    // STATE
    medias,
    media,
    mediasPages,
    mediasCurrentPage,
    userMedias,
    otherMedias,
    bannedIds,
    communityMedias,
    communityMediasPages,
    communityMediasCurrentPage,

    // GETTERS
    getBannedIds,
    getMediaId,
    getMediaCommunity,
    getPagesCount,
    getCurrentPage,
    getCommunityPagesCount,
    getCommunityCurrentPage,

    // ACTIONS
    addContent,
    fetchMedias,
    fetchCommunityMedias,
    fetchUserMedias,
    fetchMedia,
    fetchOtherMedias,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMediasStore, import.meta.hot))
}
