import type { RouteLocationNormalized } from '#vue-router'

export const useLoadCommunityTrick = () => {
  const wapersStore = useWapersStore()
  const doNotMissStore = useDoNotMissStore()
  const tricksStore = useTricksStore()
  const challengesStore = useChallengesStore()
  const rankingStore = useRankingStore()
  const auth = useAuth()
  const popoutStore = usePopoutStore()

  return async (route: RouteLocationNormalized) => {
    try {
      const nickname = auth.data.value?.nickname
      const communityName = route.params.communityName as unknown as string

      // Keep it first if it need to exclude ids

      await Promise.all([
        wapersStore.fetchUserWapers(),
        challengesStore.fetchMonthlyChallenges(),
        tricksStore.fetchTricks({
          page: getPageQuery(route),
        }),
        tricksStore.fetchTricksCommunitySubcategories(communityName),
        tricksStore.fetchTrick({
          slug: route.params.postTitle as string,
          community: communityName,
          category: 'astuces',
          subCategory: route.params.subCategoryName as string,
        }),
        rankingStore.fetchTop({
          nickname,
        }),
        doNotMissStore.fetchDoNotMiss(),
        // Fetch data for popout
        popoutStore.fetchPopout(),
      ])
    } catch (error) {
      console.error('from page', error)
    }
  }
}
