const regexs = [
  { rule: /^\/communautes\/ocs$/, redirect: '/communautes/genre/autres' },
  { rule: /^\/communautes\/ocs\/avis-joueurs$/, redirect: '/avis-joueurs' },
  { rule: /^\/communautes\/ocs\/wapers$/, redirect: '/wapers' },
  {
    rule: /^\/communautes\/ocs\/actualites-communaute$/,
    redirect: '/actualites-communautes',
  },
  {
    rule: /^\/communautes\/ocs\/avis-joueurs\/detail\/.*$/,
    redirect: '/avis-joueurs',
  },
  {
    rule: /^\/communautes\/ocs\/actualites-communaute\/.*$/,
    redirect: '/actualites-communautes',
  },
]

export default defineNuxtRouteMiddleware((to) => {
  const { path } = to

  if (!import.meta.dev) {
    for (const reg of regexs) {
      if (reg.rule.test(path)) {
        return navigateTo(reg.redirect, { redirectCode: 301 })
      }
    }
  }
  // Redirect Contest slug prefix to index
  if (path === '/jeu-concours') {
    return navigateTo('/concours', { redirectCode: 301 })
  }

  // Redirect RecordContest slug prefix to index
  if (path === '/concours-classement') {
    return navigateTo('/concours', { redirectCode: 301 })
  }
})
