import type { Endpoints } from '~/types'

export const useContestsStore = defineStore('contestsStore', () => {
  const socialStore = useSocialStore()

  const contest = ref<Endpoints['GET__CONTEST']>()
  const contests = ref<Endpoints['GET__CONTESTS']>()

  const { authenticated } = useAuthenticated()

  const fetchContest = async ({ slug = '' }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__CONTEST']>(
      `${
        authenticated.value ? API.GET__SECURE_CONTEST : API.GET__CONTEST
      }/${slug}`,
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching contests: ',
        error,
      )
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
        }))
      : []

    socialStore.fetchSocial({
      slug,
      slugType: 'contest',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
      is_following: rawData.is_following ?? false,
    })

    contest.value = rawData
  }

  const fetchAll = async () => {
    const { data, error } = await useCustomFetch<Endpoints['GET__CONTESTS']>(
      API.GET__CONTESTS,
    )

    if (error.value || !data.value) {
      return logError(
        'from actions',
        'There was a problem fetching contests: ',
        error,
      )
    }

    contests.value = data.value
  }

  return {
    // State
    contests,
    contest,

    // Actions
    fetchContest,
    fetchAll,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContestsStore, import.meta.hot))
}
