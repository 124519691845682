import type { RouteLocationNormalized } from '#vue-router'

export const useLoadChallenges = () => {
  const auth = useAuth()
  const challengesStore = useChallengesStore()
  const metaContentStore = useMetaContentStore()
  const rankingStore = useRankingStore()

  return async (route: RouteLocationNormalized) => {
    try {
      const { nickname } = auth.data.value ?? {}

      if (auth.status.value === 'authenticated') {
        // Fetch user followed challenged if logged
        await challengesStore.fetchUserChallenges()
      }

      await Promise.all([
        // Fetch challenges list
        challengesStore.fetchChallenges({
          page: getPageQuery(route),
        }),
        // Fetch data about top ranking
        rankingStore.fetchTop({
          nickname,
        }),
        // Fetch SEO content
        metaContentStore.fetchTransverseContent({ slug: route.name }),
      ])

      if (challengesStore.getPagesCount === -1) {
        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
