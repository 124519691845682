import type { RouteLocationNormalized } from '#vue-router'

export const useLoadWapersCornerCommunitySubCategory = () => {
  const communitiesStore = useCommunitiesStore()
  const waperCornersStore = useWaperCornersStore()

  return async (route: RouteLocationNormalized) => {
    try {
      await Promise.all([
        // Fetch data about the community
        communitiesStore.fetchCommunity({
          slug: route.params.communityName as string,
        }),
        // Fetch post categories from the community
        waperCornersStore.fetchWaperCornersCommunitySubcategories(
          route.params.communityName as string,
        ),
        // Fetch posts `waperCorners` from the community subcategory
        waperCornersStore.fetchCommunitySubcategoriesWaperCorners({
          page: getPageQuery(route),
          community: route.params.communityName as string,
          subCategory: route.params.subCategoryName as string,
        }),
      ])
    } catch (error) {
      console.error('from page', error)
    }
  }
}
