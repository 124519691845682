import type { RouteLocationNormalized } from '#vue-router'

export const useLoadContests = () => {
  const contestsStore = useContestsStore()
  const metaContentStore = useMetaContentStore()

  return async (route: RouteLocationNormalized) => {
    await Promise.all([
      contestsStore.fetchAll(),
      metaContentStore.fetchTransverseContent({
        slug: route.name,
      }),
    ])
  }
}
