import type { CustomChallenge, Endpoints, FormattedChallenge } from '~/types'
import { API } from '~/utils/constants'
import { useSocialStore } from './social'

export const useChallengesStore = defineStore('challengesStore', () => {
  const challenges = ref<CustomChallenge[]>([])
  const challengesPages = ref(1)
  const challengesCurrentPage = ref(1)
  const userChallenges = ref<CustomChallenge[]>([])
  const monthlyChallenges = ref<FormattedChallenge[]>([])
  const challenge = ref<CustomChallenge>()
  const bannedIds = ref<number[]>([])

  const getBannedIds = computed(() => bannedIds.value)
  const getChallengeId = computed(() => challenge.value?.id ?? '')
  const getPagesCount = computed(() => challengesPages.value)
  const getCurrentPage = computed(() => challengesCurrentPage.value)

  const setChallenges = (challengeItems: CustomChallenge[]) => {
    challenges.value = challengeItems
  }

  const setUserChallenges = (challengeItems: CustomChallenge[]) => {
    userChallenges.value = challengeItems
  }

  const setMonthlyChallenges = (challengeItems: FormattedChallenge[]) => {
    monthlyChallenges.value = challengeItems
  }

  const setChallengesPages = (pages: number) => {
    challengesPages.value = pages
  }

  const setChallengesCurrentPage = (page: number) => {
    challengesCurrentPage.value = page
  }

  const setBannedIds = (ids: number[]) => {
    bannedIds.value = ids
  }

  const setChallenge = (challengeItem?: CustomChallenge) => {
    challenge.value = challengeItem
  }

  const fetchChallenges = async ({ page = 1 }) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__CHALLENGE_SEARCH']
    >(API.GET__CHALLENGE_SEARCH, {
      query: {
        page,
        limit: 36,
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      setChallenges([])
      return logError(
        'from actions',
        'There was a problem fetching challenges: ',
        error,
      )
    }
    const formattedData = rawData.results.map((element) => ({
      ...element,
      type: 'challenge',
      user: element.contributor,
      route: `/challenge/${element.slug}`,
    }))

    setChallenges(formattedData)
    setChallengesPages(rawData.pages_count)
    setChallengesCurrentPage(page)
  }

  const fetchMonthlyChallenges = async (fromChallenge = false) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__MONTHLY_CHALLENGES']
    >(API.GET__MONTHLY_CHALLENGES)

    const rawData = unref(data)
    if (error.value || !rawData) {
      setMonthlyChallenges([])
      return logError(
        'from actions',
        'There was a problem fetching monthly challenges: ',
        error,
      )
    }
    const formattedData: FormattedChallenge[] = []

    rawData.forEach((element) => {
      if (element.id !== getChallengeId.value || !fromChallenge) {
        formattedData.push({
          ...element,
          type: 'challenge',
          user: element.contributor,
          route: `/challenge/${element.slug}`,
        })
      }
    })

    setMonthlyChallenges(formattedData)
  }

  const fetchUserChallenges = async () => {
    const postIds: number[] = []
    const formattedData: FormattedChallenge[] = []
    const { data, error } = await useCustomFetch<
      Endpoints['GET__MONTHLY_CHALLENGES']
    >(API.GET__MONTHLY_CHALLENGES, {
      query: {
        type: 'user',
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      setUserChallenges([])
      setBannedIds([])
      return logError(
        'from actions',
        'There was a problem fetching user challenges: ',
        error,
      )
    }

    rawData.forEach((element) => {
      if (element.id) {
        postIds.push(element.id)
      }
      formattedData.push({
        ...element,
        type: 'challenge',
        user: element.contributor,
        route: `/challenge/${element.slug}`,
      })
    })

    setUserChallenges(formattedData)
    setBannedIds(postIds)
  }

  const socialStore = useSocialStore()

  const fetchChallenge = async ({ slug }: { slug: string }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__CHALLENGE']>(
      `${API.GET__CHALLENGE}/${slug}`,
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      setChallenge()
      return logError(
        'from actions',
        'There was a problem fetching challenge: ',
        error,
      )
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    socialStore.fetchSocial({
      slug,
      slugType: 'challenge',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
      is_following: rawData.is_following,
    })

    setChallenge({ ...rawData })
  }

  return {
    // State
    challenges,
    userChallenges,
    monthlyChallenges,
    challenge,
    bannedIds,
    challengesPages,
    challengesCurrentPage,

    // Getters
    getBannedIds,
    getChallengeId,
    getPagesCount,
    getCurrentPage,

    // Mutations
    setChallenges,
    setUserChallenges,
    setMonthlyChallenges,
    setChallenge,
    setBannedIds,
    setChallengesPages,
    setChallengesCurrentPage,

    // Actions
    fetchChallenges,
    fetchUserChallenges,
    fetchMonthlyChallenges,
    fetchChallenge,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChallengesStore, import.meta.hot))
}
