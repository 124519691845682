import type { RouteLocation } from '#vue-router'
import type { Endpoints } from '~/types'

export const useMetaContentStore = defineStore('metaContentStore', () => {
  // State
  const transverses = ref<Endpoints['GET_META_CONTENT'][]>([])

  // Getters
  const getTransverses = computed(() => {
    return transverses.value
  })

  const getTransverseBySlug = (slug?: RouteLocation['name']) =>
    computed(() => {
      return transverses.value.find((content) => content.slug === slug)
    })

  // Actions
  const fetchTransverseContent = async ({
    slug,
  }: {
    slug?: RouteLocation['name']
  }) => {
    if (!slug || getTransverseBySlug(slug).value) return

    const { data, error } = await useCustomFetch<Endpoints['GET_META_CONTENT']>(
      `${API.GET_META_CONTENT}/${String(slug)}`,
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching transverse content: ',
        error,
      )
    }

    transverses.value = [
      ...transverses.value.filter((content) => content.slug !== rawData.slug),
      rawData,
    ]
  }

  return {
    // STATE
    transverses,

    // GETTERS
    getTransverses,
    getTransverseBySlug,

    // ACTIONS
    fetchTransverseContent,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMetaContentStore, import.meta.hot))
}
