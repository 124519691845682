import type { RouteLocationNormalized } from '#vue-router'

export const useLoadCommunity = () => {
  const auth = useAuth()
  const communityStore = useCommunitiesStore()
  const challengeStore = useChallengesStore()
  const rankingStore = useRankingStore()
  const sliderStore = useDoNotMissStore()

  const { authenticated } = useAuthenticated()

  return async (route: RouteLocationNormalized) => {
    try {
      const { nickname } = auth.data.value ?? {}

      await Promise.all([
        // Fetch data about actual challenges
        challengeStore.fetchMonthlyChallenges(authenticated.value),
        // Fetch data about top ranking
        rankingStore.fetchTop({
          nickname,
        }),
        // Fetch data about the community
        communityStore.fetchCommunity({
          slug: route.params.communityName as string,
        }),
        // Fetch data for slider `Do Not Miss`
        sliderStore.fetchDoNotMiss(),
      ])
    } catch (error) {
      console.error('from page', error)
    }
  }
}
