<template>
  <NuxtLayout>
    <LoadingSpinner />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useRecaptchaProvider } from 'vue-recaptcha'
useRecaptchaProvider()
</script>
