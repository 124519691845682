import type {
  CustomCommunityWithRoute,
  Endpoints,
  FormattedWaper,
  Pagination,
  PostCategories,
  UserProfile,
  UserWithRoute,
} from '~/types'

export const useWapersStore = defineStore('wapersStore', () => {
  // STATE
  const waper = ref<FormattedWaper>()
  const wapers = ref<UserWithRoute[]>([])
  const wapersPages = ref(1)
  const wapersCurrentPage = ref(1)
  const userWapers = ref<UserWithRoute[]>([])
  const bannedIds = ref<number[]>([])
  const communityWapers = ref<UserWithRoute[]>([])
  const communityWapersPages = ref(1)
  const communityWapersCurrentPage = ref(1)

  // GETTERS
  const getBannedIds = computed(() => bannedIds.value)
  const getWaper = computed(() => waper.value)
  const getTopWapers = computed(() =>
    wapers.value
      .sort((a, b) => {
        if (!a.points_total) return 1
        if (!b.points_total) return -1
        return b.points_total - a.points_total
      })
      .slice(0, 3),
  )
  const getPagesCount = computed(() => wapersPages.value)
  const getCurrentPage = computed(() => wapersCurrentPage.value)

  // MUTATIONS
  const addWaper = (waperItem: UserWithRoute) => {
    wapers.value.push(waperItem)
  }

  const setWapers = (wapersList: UserWithRoute[]) => {
    wapers.value = wapersList
  }

  const setUserWapers = (wapersList: UserWithRoute[]) => {
    userWapers.value = wapersList
  }

  const setWapersPages = (page: number) => {
    wapersPages.value = page
  }

  const setWapersCurrentPage = (page: number) => {
    wapersCurrentPage.value = page
  }

  const setBannedIds = (ids: number[]) => {
    bannedIds.value = ids
  }

  const setWaper = (waperItem: FormattedWaper) => {
    waper.value = { ...waperItem }
  }

  const setCommunityWapers = (communityWapersList: UserWithRoute[]) => {
    communityWapers.value = communityWapersList
  }

  const addMoreWaperCommunities = (communities: CustomCommunityWithRoute[]) => {
    if (waper.value) {
      waper.value.communities.push(...communities)
      waper.value.pagination.communities.current_page += 1
    }
  }

  const addMoreWaperWapers = (wapersList: UserWithRoute[]) => {
    if (!waper.value) return

    waper.value.wapers.push(...wapersList)
    waper.value.pagination.wapers.current_page += 1
  }

  const addMoreWaperContribs = (contrib: FormattedWaper['contribs']) => {
    if (!waper.value) return

    waper.value.contribs.push(...contrib)
    contrib.forEach((item) => {
      if (waper.value?.pagination[item.typeContrib]) {
        waper.value.pagination[item.typeContrib].current_page += 1
      }
    })
  }

  const setCommunityWapersPages = (communityWapersPagesList: number) => {
    communityWapersPages.value = communityWapersPagesList
  }

  const setCommunityWapersCurrentPage = (page: number) => {
    communityWapersCurrentPage.value = page
  }

  const updateFollowWaper = (payload: boolean) => {
    if (!waper.value) {
      return
    }

    let followers = waper.value.stats.followers_count
    if (payload) {
      followers++
    } else {
      followers--
    }
    waper.value.is_following = payload
    waper.value.stats.followers_count = followers
  }

  // ACTIONS
  const fetchMoreWaperCommunities = async () => {
    if (!waper.value) return

    const url = waper.value.isUser
      ? API.GET__SECURE_COMMUNITY_FOLLOWING
      : API.GET__COMMUNITY_FOLLOWING

    const { data, error } = await useCustomFetch<
      Endpoints['GET__COMMUNITY_FOLLOWING']
    >(url, {
      query: {
        page: waper.value.pagination.communities.current_page + 1,
        sort: 'date',
      },
    })

    const rawData = unref(data)

    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching more communities: ',
        error,
      )
    }

    const formattedCommunities = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.slug}`,
    }))
    addMoreWaperCommunities(formattedCommunities)
  }

  const fetchMoreWaperContribs = async (
    typeContrib: PostCategories[number],
  ) => {
    if (!waper.value) return

    if (typeContrib === 'avis-joueurs') {
      const { data, error } = await useCustomFetch<
        Endpoints['GET__REVIEWS_SEARCH']
      >(API.GET__REVIEWS_SEARCH, {
        query: {
          user_nickname: waper.value.nickname,
          page: waper.value.pagination[typeContrib].current_page + 1,
          limit: '16',
          sort: 'date',
        },
      })

      const rawData = unref(data)

      if (error.value || !rawData) {
        return logError(
          'from actions',
          'There was a problem fetching more contribs: ',
          error,
        )
      }
      const formattedContrib = rawData.results.map((element) => {
        return {
          ...element,
          typeContrib,
          route: `/communautes/${element.community?.slug}/${typeContrib}/detail/${element.slug}`,
        }
      })

      return addMoreWaperContribs(formattedContrib)
    }

    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          user_nickname: waper.value.nickname,
          page: waper.value.pagination[typeContrib].current_page + 1,
          limit: '16',
          category_slug: typeContrib,
        },
      },
    )

    const rawData = unref(data)

    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching more contribs: ',
        error,
      )
    }

    if (typeContrib === 'astuces' || typeContrib === 'le-coin-des-wapers') {
      const formattedContribs = rawData.results.map((element) => {
        return {
          ...element,
          typeContrib,
          route: `/communautes/${element.community.slug}/${renameWapersCornerUrl(typeContrib)}/${
            element.sub_category ? element.sub_category.slug : 'nodata'
          }/${element.slug}`,
        }
      })
      return addMoreWaperContribs(formattedContribs)
    }

    const formattedContribs = rawData.results.map((element) => {
      return {
        ...element,
        typeContrib,
        route: `/communautes/${element.community.slug}/${typeContrib}/${element.slug}`,
      }
    })
    return addMoreWaperContribs(formattedContribs)
  }

  const fetchMoreWaperWapers = async () => {
    if (!waper.value) return

    const baseQuery = {
      page: waper.value.pagination.wapers.current_page + 1,
      sort: 'date',
    }

    const urlAndQuery = waper.value.isUser
      ? {
          url: API.GET__SECURE_USER_WAPERS,
          query: baseQuery,
        }
      : {
          url: API.GET__USER_WAPERS,
          user_nickname: waper.value.nickname,
          query: {
            user_nickname: waper.value.nickname,
            ...baseQuery,
          },
        }

    const { data, error } = await useCustomFetch<Endpoints['GET__USER_WAPERS']>(
      urlAndQuery.url,
      {
        query: urlAndQuery.query,
      },
    )

    const rawData = unref(data)

    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching more wapers: ',
        error,
      )
    }

    const formattedWapers = rawData.results.map((element) => ({
      ...element,
      route: `/wapers/${element.slug}`,
    }))

    addMoreWaperWapers(formattedWapers)
  }

  const followWaper = async () => {
    if (!waper.value) return

    const { error } = await useCustomFetch(
      `${API.POST__FOLLOW_WAPER}/${waper.value.nickname}`,
      {
        method: 'POST',
      },
    )

    if (error.value) {
      return logError(
        'from actions',
        'There was a problem following waper: ',
        error,
      )
    }

    updateFollowWaper(true)
  }

  const unfollowWaper = async () => {
    if (!waper.value) return

    const { error } = await useCustomFetch(
      `${API.POST__UNFOLLOW_WAPER}/${waper.value.nickname}`,
      {
        method: 'POST',
      },
    )
    if (error.value) {
      return logError(
        'from actions',
        'There was a problem ufollow waper: ',
        error,
      )
    }

    updateFollowWaper(false)
  }

  const fetchWapers = async ({ page = 1, limit = 80, search = '' }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__USER_SEARCH']>(
      API.GET__USER_SEARCH,
      {
        query: {
          q: search,
          banned_ids: bannedIds.value,
          page,
          limit,
          sort: 'date',
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      setWapers([])
      return logError(
        'from action fetchWapers',
        'There was a problem unfollow waper: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/wapers/${element.slug}`,
    }))

    setWapers(formattedData)
    setWapersPages(rawData.pages_count)
    setWapersCurrentPage(page)
  }

  const fetchUserWapers = async () => {
    if (!authenticated.value) return
    const postIds: number[] = []
    const formattedData: UserWithRoute[] = []

    const { data, error } = await useCustomFetch<
      Endpoints['GET__SECURE_USER_WAPERS']
    >(API.GET__SECURE_USER_WAPERS, {
      query: {
        limit: '40',
      },
    })

    const rawData = unref(data)

    if (error.value || !rawData) {
      setUserWapers([])
      return logError(
        'from action fetchUserWapers',
        'There was a problem fetching user wapers: ',
        error,
      )
    }

    rawData.results.forEach((element) => {
      if (element.id) {
        postIds.push(element.id)
      }

      formattedData.push({
        ...element,
        route: `/wapers/${element.slug}`,
      })
    })
    setUserWapers(formattedData)
    setBannedIds(postIds)
  }

  const fetchCommunityWapers = async ({
    page = 1,
    community,
    limit = 1,
  }: {
    page: number
    community?: string
    limit: number
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__USER_SEARCH']>(
      API.GET__USER_SEARCH,
      {
        query: {
          community_slug: community,
          page,
          limit,
        },
      },
    )

    const rawData = unref(data)

    if (error.value || !rawData) {
      setCommunityWapers([])
      return logError(
        'from actions',
        'There was a problem fetching community actualities: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/wapers/${element.slug}`,
    }))
    setCommunityWapers(formattedData)
    setCommunityWapersPages(rawData.pages_count)
    setCommunityWapersCurrentPage(10)
  }

  const getFormattedContrib = (
    userInfos: Endpoints['GET__USER'],
    pagination: Pagination,
    data: UserProfile,
  ) => {
    const formattedContrib: FormattedWaper['contribs'] = []
    if (!userInfos.has_post_in_categories) return formattedContrib

    userInfos.has_post_in_categories.forEach((element) => {
      pagination[element] = {
        slug: element,
        pages_count: data.posts[element].pages_count,
        current_page: 1,
      }

      data.posts[element].results.forEach((elementResponse) => {
        if (element === 'astuces' || element === 'le-coin-des-wapers') {
          formattedContrib.push({
            ...elementResponse,
            typeContrib: element,
            route: `/communautes/${elementResponse.community.slug}/${renameWapersCornerUrl(element)}/${
              elementResponse.sub_category
                ? elementResponse.sub_category.slug
                : 'nodata'
            }/${elementResponse.slug}`,
          })
        } else if (element === 'avis-joueurs') {
          formattedContrib.push({
            ...elementResponse,
            typeContrib: element,
            route: `/communautes/${elementResponse.community.slug}/${element}/detail/${elementResponse.slug}`,
          })
        } else {
          formattedContrib.push({
            ...elementResponse,
            typeContrib: element,
            route: `/communautes/${elementResponse.community.slug}/${element}/${elementResponse.slug}`,
          })
        }
      })
    })
    return formattedContrib
  }

  const getPagination = (data: UserProfile) => {
    const pagination: Pagination = {
      communities: {
        slug: 'communities',
        pages_count: data.community_following.pages_count,
        current_page: 1,
      },
      wapers: {
        slug: 'wapers',
        pages_count: data.user_following.pages_count,
        current_page: 1,
      },
    }
    return pagination
  }

  const getFormattedWaper = (
    data: UserProfile,
    userInfos: Endpoints['GET__USER'],
  ) => {
    const communities_count = data.community_following.results_count
    const formattedCommunities = data.community_following.results.map(
      (element) => ({
        ...element,
        route: `/communautes/${element.slug}`,
      }),
    )

    const wapers_count = data.user_following.results_count
    const formattedWapers = data.user_following.results.map((element) => ({
      ...element,
      route: `/wapers/${element.slug}`,
    }))

    const pagination = getPagination(data)

    const formattedContrib = getFormattedContrib(userInfos, pagination, data)

    return {
      ...userInfos,
      communities: formattedCommunities,
      wapers: formattedWapers,
      communities_count,
      wapers_count,
      contribs: formattedContrib,
      pagination,
      isUser: true,
    }
  }

  const { authenticated } = useAuthenticated()

  const fetchWaper = async ({
    nickname,
    isUser = false,
  }: {
    nickname: string
    isUser: boolean
  }) => {
    const { user } = useTempAuth()

    if (isUser && user.value) {
      const { data, error } = await useCustomFetch<Endpoints['GET__PROFILE']>(
        `/secure${API.GET__PROFILE}/${user.value.slug}`,
      )

      const rawData = unref(data)
      if (error.value || !rawData) {
        return logError(
          'from actions',
          'There was a problem fetching waper: ',
          error,
        )
      }

      const formattedWaper = getFormattedWaper(rawData, user.value)

      return setWaper(formattedWaper)
    }

    const { data: waper, error: userError } = await useCustomFetch<
      Endpoints['GET__USER']
    >(`${authenticated.value ? '/secure' : ''}${API.GET__USER}/${nickname}`)

    const rawWaper = unref(waper)
    if (userError.value || !rawWaper) {
      return logError(
        'from actions',
        'There was a problem fetching waper: ',
        userError,
      )
    }

    const { data, error } = await useCustomFetch<Endpoints['GET__PROFILE']>(
      `${API.GET__PROFILE}/${rawWaper.slug}`,
    )

    const rawData = unref(data)

    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching waper: ',
        error,
      )
    }

    const formattedWaper = getFormattedWaper(rawData, rawWaper)

    return setWaper(formattedWaper)
  }

  return {
    // STATE
    wapers,
    wapersPages,
    wapersCurrentPage,
    userWapers,
    waper,
    bannedIds,
    communityWapers,
    communityWapersPages,
    communityWapersCurrentPage,

    // GETTERS
    getBannedIds,
    getWaper,
    getTopWapers,
    getPagesCount,
    getCurrentPage,

    // MUTATIONS
    addWaper,
    setWaper,
    setUserWapers,
    setWapersPages,
    setWapersCurrentPage,
    setBannedIds,
    setCommunityWapers,
    addMoreWaperCommunities,
    addMoreWaperWapers,
    addMoreWaperContribs,
    setCommunityWapersPages,
    setCommunityWapersCurrentPage,
    updateFollowWaper,

    // ACTIONS
    fetchMoreWaperCommunities,
    fetchMoreWaperContribs,
    fetchMoreWaperWapers,
    followWaper,
    unfollowWaper,
    fetchWaper,
    fetchWapers,
    fetchUserWapers,
    fetchCommunityWapers,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWapersStore, import.meta.hot))
}
