import type { RouteLocationNormalized } from '#vue-router'

export const useLoadActualitiesCommunity = () => {
  const communitiesStore = useCommunitiesStore()
  const actualitiesStore = useActualitiesStore()

  return async (route: RouteLocationNormalized) => {
    await Promise.all([
      // Fetch data about the community
      communitiesStore.fetchCommunity({
        slug: route.params.communityName as string,
      }),
      // Fetch posts `actualities` from the community

      actualitiesStore.fetchCommunityActualities({
        page: getPageQuery(route),
        community: route.params.communityName as string,
      }),
    ])

    if (actualitiesStore.getPagesCount === -1) {
      return navigateTo(route.path, { replace: true })
    }
  }
}
