import routerOptions0 from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/web/wap/_online/vue_src/nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}