import type { CustomOffer, CustomOfferWithRoute, Endpoints } from '~/types'

export const useOffersStore = defineStore('offersStore', () => {
  const socialStore = useSocialStore()

  const offers = ref<CustomOfferWithRoute[]>([])
  const offer = ref<CustomOffer>()
  const offersPages = ref(1)
  const offersCurrentPage = ref(1)

  // MUTATIONS
  const setOffers = (offerList: CustomOfferWithRoute[]) => {
    offers.value = offerList
  }
  const setOffer = (offerItem?: CustomOffer) => {
    offer.value = offerItem
  }
  const setOffersPage = (pages_count: number) => {
    offersPages.value = pages_count
  }
  const setOffersCurrentPage = (page: number) => {
    offersCurrentPage.value = page
  }

  // GETTERS
  const getPagesCount = computed(() => offersPages.value)
  const getCurrentPage = computed(() => offersCurrentPage.value)

  // ACTIONS
  const fetchOffers = async (page = 1) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__OFFERS_SEARCH']
    >(API.GET__OFFERS_SEARCH, {
      query: {
        page,
        sort: 'date',
      },
    })

    const rawData = unref(data)

    if (error.value || !rawData) {
      setOffers([])
      return logError(
        'from actions',
        'There was a problem fetching offers: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/offres/${element.slug}`,
    }))

    setOffers(formattedData)
    setOffersPage(rawData.pages_count)
    setOffersCurrentPage(page)
  }

  const fetchOffer = async ({ slug = '' }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__OFFER']>(
      `${API.GET__OFFER}/${slug}`,
    )

    const rawData = unref(data)

    if (error.value || !rawData) {
      setOffer()
      return logError(
        'from actions',
        'There was a problem fetching offer: ',
        error,
      )
    }

    const formattedComments =
      rawData.comments?.map((comment) => ({
        ...comment,
        currentPage: 1,
        isOpen: false,
      })) ?? []

    socialStore.fetchSocial({
      slug,
      slugType: 'offer',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
    })

    setOffer(rawData)
  }

  return {
    // STATE
    offers,
    offer,
    offersPages,
    offersCurrentPage,

    // GETTERS
    getPagesCount,
    getCurrentPage,

    // ACTIONS
    fetchOffers,
    fetchOffer,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOffersStore, import.meta.hot))
}
