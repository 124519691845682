import type { CredentialResponse } from 'google-one-tap'
import type { Endpoints } from '~/types'
import type { SessionData } from '#auth'
import { useTempJWTCookie } from '~/composables/useAuthenticated'

export const myFetch = $fetch.create({
  async onRequest({ options }) {
    options.baseURL = useRuntimeConfig().public.wsUrl
  },
})

export const googleRegister = async (googleJwt: string) => {
  return myFetch<Endpoints['POST__REGISTER_GOOGLE']>(
    API.POST__REGISTER_GOOGLE,
    {
      method: 'POST',
      body: { googleJwt },
    },
  )
}

export const getUser = async (token: string) => {
  const bearer = `Bearer ${token}`

  return myFetch<SessionData>(`/secure${API.GET__USER}`, {
    headers: {
      Authorization: bearer,
    },
  })
}

export const googleAuthCallback = async (response: CredentialResponse) => {
  try {
    if (!response.credential) {
      throw new Error('No credential found')
    }

    const { token } = await googleRegister(response.credential)

    const user = await getUser(token)

    if (!user?.birthday || !user.civility || !user.nickname || !user.lastname) {
      useTempUser().value = user
      useTempJWTCookie().value = token
      return await useRouter().push('/mon-profil')
    }

    const { setToken, data } = useAuthState()

    setToken(token)
    data.value = user
  } catch (err) {
    console.error(err)
  }
}
