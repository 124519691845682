import type { Endpoints, PostWithRoute } from '~/types'
import type { FormattedCustomRanking } from '~/types/ranking'

export const useRankingStore = defineStore('rankingsStore', () => {
  const ranks = ref<PostWithRoute[]>([])
  const top = ref<FormattedCustomRanking[]>([])
  const ranksPage = ref(1)

  const fetchRanks = async ({ page = 1, search = '' }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          page,
          q: search,
          sort: 'date',
        },
      },
    )

    if (!error.value && data.value) {
      const formattedData = data.value.results.map((element) => ({
        ...element,
        route: `/offres/${element.user?.slug}`,
      }))
      ranks.value = formattedData
      ranksPage.value = data.value.pages_count
    } else {
      ranks.value = []
      console.error(
        'from actions',
        'There was a problem fetching ranks: ',
        error,
      )
    }
  }

  const fetchTopWaper = async (nickname: string) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__RANKING_TOP']>(
      API.GET__RANKING_TOP,
      {
        query: {
          user_nickname: nickname,
        },
      },
    )

    const formattedData: FormattedCustomRanking[] = []
    let alreadyWap = false

    if (!error.value && data.value) {
      data.value.forEach(function (element) {
        if (
          !alreadyWap &&
          element.user?.nickname.toLowerCase() === nickname.toLowerCase()
        ) {
          alreadyWap = true
          formattedData.push({
            ...element,
            rankUser: true,
          })
        } else if (
          element.user?.nickname.toLowerCase() !== nickname.toLowerCase()
        ) {
          formattedData.push(element)
        }
      })
      top.value = formattedData
    } else {
      top.value = []
      console.error(
        'from actions',
        'There was a problem fetching topWapers: ',
        error,
      )
    }
  }

  const fetchTop = async (
    { nickname }: { nickname?: string } = { nickname: undefined },
  ) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__RANKING_TOP']>(
      API.GET__RANKING_TOP,
    )

    const rawData = unref(data)

    if (error.value || !rawData) {
      top.value = []
      return logError(
        'from actions',
        'There was a problem fetching top: ',
        error,
      )
    }

    if (!nickname) {
      top.value = rawData
      return
    }

    const formattedData = rawData.map((element) => {
      if (element.user?.nickname !== nickname) {
        return element
      }

      return {
        ...element,
        rankUser: true,
      }
    })

    top.value = formattedData
  }

  return {
    // STATE
    ranks,
    top,
    ranksPage,

    // ACTIONS
    fetchRanks,
    fetchTopWaper,
    fetchTop,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRankingStore, import.meta.hot))
}
