import type { RouteLocationNormalized } from '#vue-router'

export const useLoadTournament = () => {
  const tournamentsStore = useTournamentsStore()
  const wapersStore = useWapersStore()

  return async (route: RouteLocationNormalized) => {
    try {
      await Promise.all([
        tournamentsStore.fetchTournament({
          slug: route.params.tournamentName as string,
        }),
        wapersStore.fetchUserWapers(),
      ])
    } catch (error) {
      console.error('from page', error)
    }
  }
}
