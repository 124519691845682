import { useFetch } from '#imports'
import { defu } from 'defu'
import type { UseFetchOptions } from 'nuxt/app'

export function useCustomFetch<T>(
  url: MaybeRef<string> | (() => string),
  options: UseFetchOptions<T> = {},
) {
  const runtimeConfig = useRuntimeConfig()
  const { wsUrl } = runtimeConfig.public

  if (!wsUrl) {
    throw new Error(
      `API base URL (${wsUrl}) is not defined,
      check the environment variable named NUXT_PUBLIC_WS_URL`,
    )
  }
  const { token, authenticated, getAuthHeader, isTemp } = useTempAuth()

  const rawURL = toValue(url)

  // URL without /secure prefix if user is not authenticated
  const formattedURL =
    rawURL.startsWith('/secure') && !authenticated.value
      ? rawURL.replace('/secure', '')
      : rawURL

  const authHeader = getAuthHeader({
    authenticated,
    isTemp,
    token,
    url: formattedURL,
  })

  const defaults: UseFetchOptions<T> = {
    baseURL: wsUrl,

    // set user token if authenticated
    headers: authHeader,
  }

  const params = defu(options, defaults)

  return useFetch(formattedURL, params)
}
