import type { RouteLocationNormalized } from '#vue-router'

export const useLoadOpinions = () => {
  const { authenticated } = useAuthenticated()
  const waperOpinionsStore = useWaperOpinionsStore()
  const metaContentStore = useMetaContentStore()

  return async (route: RouteLocationNormalized) => {
    try {
      if (authenticated.value) {
        await waperOpinionsStore.fetchUserWaperOpinions()
      }

      await Promise.all([
        waperOpinionsStore.fetchWaperOpinions({ page: getPageQuery(route) }),
        metaContentStore.fetchTransverseContent({ slug: route.name }),
      ])

      if (waperOpinionsStore.getPagesCount === -1) {
        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
