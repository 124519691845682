import type { RouteLocationNormalized } from '#vue-router'

export const useLoadPlayersReviewsCommunity = () => {
  const communitiesStore = useCommunitiesStore()
  const waperOpinionsStore = useWaperOpinionsStore()

  return async (route: RouteLocationNormalized) => {
    try {
      await Promise.all([
        // Fetch data about the community
        communitiesStore.fetchCommunity({
          slug: route.params.communityName as string,
        }),
        // Fetch user reviews from the community
        waperOpinionsStore.fetchCommunityWaperOpinions({
          page: getPageQuery(route),
          community: route.params.communityName as string,
        }),
      ])

      if (waperOpinionsStore.getCommunityPagesCount === -1) {
        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
