import type { RouteLocationNormalized } from '#vue-router'

export const useLoadWapersCommunity = () => {
  const communitiesStore = useCommunitiesStore()
  const wapersStore = useWapersStore()

  return async (route: RouteLocationNormalized) => {
    try {
      await Promise.all([
        // Fetch data about the community
        communitiesStore.fetchCommunity({
          slug: route.params.communityName as string,
        }),
        // Fetch media reviews from the community
        wapersStore.fetchCommunityWapers({
          page: getPageQuery(route),
          community: route.params.communityName as string,
          limit: 40,
        }),
      ])

      if (wapersStore.getPagesCount === -1) {
        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
