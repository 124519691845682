import type {
  Category,
  CustomPost2,
  Endpoints,
  PostWithRoute,
  WithRoute,
} from '~/types'

export const useWaperCornersStore = defineStore('waperCornersStore', () => {
  const socialStore = useSocialStore()

  // STATE
  const waperCorners = ref<PostWithRoute[]>([])
  const waperCorner = ref<WithRoute<CustomPost2>>()
  const waperCornersPages = ref(1)
  const waperCornersCurrentPage = ref(1)
  const userWaperCorners = ref<PostWithRoute[]>([])
  const otherWaperCorners = ref<PostWithRoute[]>([])
  const waperCornersSubcategoriesPages = ref(1)
  const waperCornersSubcategoriesList = ref<Category[]>([])
  const bannedIds = ref<number[]>([])
  const communityWaperCorners = ref<PostWithRoute[]>([])
  const communityWaperCornersPages = ref(1)
  const communityWaperCornersCurrentPage = ref(1)
  const communityWaperCornersSubcategoriesList = ref<Category[]>([])
  const communityWaperCornersSubcategories = ref<PostWithRoute[]>([])
  const communityWaperCornersSubcategoriesPages = ref(1)
  const communityWaperCornersSubcategoriesCurrentPage = ref(1)

  // GETTERS
  const getBannedIds = computed(() => bannedIds.value)
  const getWaperCornerId = computed(() => waperCorner.value?.id)
  const getWaperCornerCommunity = computed(
    () => waperCorner.value?.community?.slug,
  )
  const getSubCategoryBySlug = (slug: Ref<string>) =>
    computed(() =>
      communityWaperCornersSubcategoriesList.value.find(
        (subCat) => subCat.slug === slug.value,
      ),
    )

  const getPagesCount = computed(() => waperCornersPages.value)
  const getCurrentPage = computed(() => waperCornersCurrentPage.value)
  const getCommunityPagesCount = computed(
    () => communityWaperCornersPages.value,
  )
  const getCommunityCurrentPage = computed(
    () => communityWaperCornersCurrentPage.value,
  )
  const getCategoryPagesCount = computed(
    () => communityWaperCornersSubcategoriesPages.value,
  )
  const getCategoryCurrentPage = computed(
    () => communityWaperCornersSubcategoriesCurrentPage.value,
  )

  // ACTIONS
  const addContent = (content: PostWithRoute) => {
    content.route = `/communautes/${
      content.community.slug
    }/le-feed-des-players/${content.sub_category?.slug ?? 'nodata'}/${
      content.slug
    }`
    waperCorners.value.push(content)
  }

  const fetchWaperCorners = async ({
    page = 1,
    filter,
    search,
  }: {
    page?: number
    filter?: string
    search?: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          q: search,
          category_slug: 'le-coin-des-wapers',
          sub_category_slug: filter,
          page,
          banned_ids: getBannedIds.value,
          limit: 36,
          sort: 'date',
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      waperCorners.value = []

      return logError(
        'from actions',
        'There was a problem fetching waperCorners: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community.slug}/le-feed-des-players/${
        element.sub_category?.slug ?? 'nodata'
      }/${element.slug}`,
    }))

    waperCorners.value = formattedData
    waperCornersPages.value = rawData.pages_count
    waperCornersCurrentPage.value = page
  }

  const fetchWaperCornersSubcategories = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SUBCATEGORIES']
    >(`${API.GET__SUBCATEGORIES}le-coin-des-wapers`)

    const rawData = unref(data)

    if (error.value || !rawData) {
      waperCornersSubcategoriesList.value = []

      return logError(
        'from actions',
        'There was a problem fetching waper corners subcategories: ',
        error,
      )
    }

    waperCornersSubcategoriesList.value = rawData
  }

  const fetchUserWaperCorners = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SECURE_POST_COMMUNITY']
    >(API.GET__SECURE_POST_COMMUNITY, {
      query: {
        category_slug: 'le-coin-des-wapers',
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      userWaperCorners.value = []
      return logError(
        'from actions',
        'There was a problem fetching user waperCorners: ',
        error,
      )
    }

    const postIds: number[] = []
    const formattedData: PostWithRoute[] = []

    rawData.forEach((element) => {
      if (element.id) postIds.push(element.id)
      formattedData.push({
        ...element,
        route: `/communautes/${element.community.slug}/le-feed-des-players/${
          element.sub_category?.slug ?? 'nodata'
        }/${element.slug}`,
      })
    })

    userWaperCorners.value = formattedData
    bannedIds.value = postIds
  }

  const fetchWaperCornersCommunitySubcategories = async (community: string) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SUBCATEGORIES']
    >(`${API.GET__SUBCATEGORIES}le-coin-des-wapers`, {
      query: { community_slug: community },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      communityWaperCornersSubcategoriesList.value = []
      return logError(
        'from actions',
        'There was a problem fetching waper corners subcategories list: ',
        error,
      )
    }

    communityWaperCornersSubcategoriesList.value = rawData
  }

  const fetchCommunityWaperCorners = async ({
    page = 1,
    community,
    search,
    filter,
  }: {
    page?: number
    community?: string
    search?: string
    filter?: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          q: search,
          community_slug: community,
          category_slug: 'le-coin-des-wapers',
          sub_category_slug: filter,
          limit: 36,
          sort: 'date',
          page,
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      communityWaperCorners.value = []
      return logError(
        'from actions',
        'There was a problem fetching waper corners',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community.slug}/le-feed-des-players/${
        element.sub_category?.slug ?? 'nodata'
      }/${element.slug}`,
    }))
    communityWaperCorners.value = formattedData
    communityWaperCornersPages.value = rawData.pages_count
    communityWaperCornersCurrentPage.value = page
  }

  const fetchCommunitySubcategoriesWaperCorners = async ({
    page = 1,
    community,
    subCategory,
    search,
  }: {
    page?: number
    community?: string
    subCategory?: string
    search?: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          q: search,
          category_slug: 'le-coin-des-wapers',
          sub_category_slug: subCategory,
          community_slug: community,
          page,
          limit: '36',
          sort: 'date',
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      communityWaperCornersSubcategories.value = []

      return logError(
        'from actions',
        'There was a problem fetching subcategory waper corner: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community.slug}/le-feed-des-players/${subCategory}/${element.slug}`,
    }))
    communityWaperCornersSubcategories.value = formattedData
    communityWaperCornersSubcategoriesPages.value = rawData.pages_count
    communityWaperCornersSubcategoriesCurrentPage.value = page
  }

  const fetchWaperCorner = async ({
    slug,
    community,
    category,
    subCategory,
  }: {
    slug: string
    community: string
    category: string
    subCategory?: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST']>(
      `${API.GET__POST}/${slug}`,
      {
        query: {
          community_slug: community,
          category_slug: category,
          sub_category_slug: subCategory,
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      waperCorner.value = undefined
      return logError(
        'from actions',
        'There was a problem fetching waper corner: ',
        error,
      )
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    const formattedData = {
      ...rawData,
      route: `/communautes/${rawData.community?.slug}/${renameWapersCornerUrl(rawData.category.slug)}/${rawData.slug}`,
    }

    socialStore.fetchSocial({
      slug,
      slugType: 'le-coin-des-wapers',
      likes_count: rawData.likes_count ?? 0,
      is_liked: !!rawData.is_liked,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: !!rawData.has_more_comments,
      is_following: !!rawData.is_following,
    })

    waperCorner.value = formattedData
    await fetchOtherWaperCorners()
  }

  const fetchOtherWaperCorners = async (subCategory?: string) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          banned_ids: getWaperCornerId.value,
          category_slug: 'le-coin-des-wapers',
          sub_category_slug: subCategory,
          community_slug: getWaperCornerCommunity.value,
          page: '1',
          limit: '12',
          sort: 'date',
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      otherWaperCorners.value = []

      return logError(
        'from actions',
        'There was a problem fetching other waperCorners: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => {
      return {
        ...element,
        route: `/communautes/${element.community.slug}/${renameWapersCornerUrl(element.category.slug)}/${
          element.sub_category?.slug ?? 'nodata'
        }/${element.slug}`,
      }
    })
    otherWaperCorners.value = formattedData
  }

  return {
    // STATE
    waperCorners,
    waperCorner,
    waperCornersPages,
    waperCornersCurrentPage,
    userWaperCorners,
    otherWaperCorners,
    waperCornersSubcategoriesPages,
    waperCornersSubcategoriesList,
    bannedIds,
    communityWaperCorners,
    communityWaperCornersPages,
    communityWaperCornersCurrentPage,
    communityWaperCornersSubcategoriesList,
    communityWaperCornersSubcategories,
    communityWaperCornersSubcategoriesPages,
    communityWaperCornersSubcategoriesCurrentPage,

    // GETTERS
    getSubCategoryBySlug,
    getPagesCount,
    getCurrentPage,
    getCommunityPagesCount,
    getCommunityCurrentPage,
    getCategoryPagesCount,
    getCategoryCurrentPage,

    // ACTIONS
    addContent,
    fetchWaperCorners,
    fetchWaperCornersSubcategories,
    fetchUserWaperCorners,
    fetchWaperCornersCommunitySubcategories,
    fetchCommunityWaperCorners,
    fetchCommunitySubcategoriesWaperCorners,
    fetchWaperCorner,
    fetchOtherWaperCorners,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWaperCornersStore, import.meta.hot))
}
