import type { Endpoints } from '~/types'
import { isNumber } from '~/utils/helpers'

export const useWallContestsStore = defineStore('wallContestsStore', () => {
  const socialStore = useSocialStore()

  // STATE
  const wallContest = ref<Endpoints['GET__WALLCONTEST']>()
  const currentPost = ref<Endpoints['GET__WALLCONTESTPOST']>()
  const postsPages = ref(1)
  const bannedIds = ref<number[]>([])
  const loadingMorePost = ref(false)

  // GETTERS
  const getPosts = computed(() => wallContest.value?.random_posts ?? [])
  const getPostsPage = computed(() => postsPages.value)
  const getBannedIds = computed(() => bannedIds.value)

  // ACTIONS
  const fetchWallContest = async ({ slug }: { slug: string }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__WALLCONTEST']>(
      `${API.GET__WALLCONTEST}/${slug}`,
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      wallContest.value = undefined
      return logError(
        'from actions',
        'There was a problem fetching wallContest: ',
        error,
      )
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    const postsIds = rawData.random_posts
      ? rawData.random_posts.map((item) => item.id).filter(isNumber)
      : []

    socialStore.fetchSocial({
      slug,
      slugType: 'wallContest',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
    })

    wallContest.value = rawData
    bannedIds.value = postsIds
  }

  const updatePostLike = ({ slug = '', liked = false }) => {
    const posts = getPosts.value
    const currentIndex = posts.findIndex((post) => post.slug === slug)

    const likesCount = posts[currentIndex].likes_count ?? 0
    posts[currentIndex] = {
      ...posts[currentIndex],
      likes_count: liked ? likesCount + 1 : Math.max(0, likesCount - 1),
      is_liked: liked,
    }

    if (wallContest.value) {
      wallContest.value.random_posts = posts
    }
  }

  const updatePostSocial = ({
    slug = '',
    likes_count = 0,
    comments_count = 0,
    is_liked = false,
  }) => {
    const posts = getPosts.value
    const currentIndex = posts.findIndex((post) => post.slug === slug)

    posts[currentIndex] = {
      ...posts[currentIndex],
      likes_count,
      is_liked,
      comments_count,
    }

    if (wallContest.value) {
      wallContest.value.random_posts = posts
    }
  }

  const moreWallContestPosts = async ({
    slug,
    page = 1,
  }: {
    slug: string
    page?: number
  }) => {
    loadingMorePost.value = true

    const { data, error } = await useCustomFetch<
      Endpoints['GET__WALLCONTESTPOSTS']
    >(API.GET__WALLCONTESTPOSTS.replace('$slug', slug), {
      query: {
        page,
        banned_ids: getBannedIds.value,
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching moreWallContestPosts: ',
        error,
      )
    }

    postsPages.value = rawData.pages_count
    if (wallContest.value) {
      wallContest.value.has_more_wc_posts = rawData.pages_count > page
      wallContest.value = {
        ...wallContest.value,
        random_posts: wallContest.value.random_posts?.concat(rawData.results),
      }
    }

    loadingMorePost.value = false
  }

  const fetchWallContestPost = async ({ slug }: { slug: string }) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__WALLCONTESTPOST']
    >(`${API.GET__WALLCONTESTPOST}/${slug}`)

    const rawData = unref(data)
    if (error.value || !rawData) {
      currentPost.value = undefined

      return logError(
        'from actions',
        'There was a problem fetching wallContestPost: ',
        error,
      )
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    currentPost.value = rawData

    socialStore.fetchSocial({
      slug,
      slugType: 'wallContestPost',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
    })
  }

  return {
    // STATE
    wallContest,
    currentPost,
    postsPages,
    bannedIds,
    loadingMorePost,

    // GETTERS
    getPostsPage,

    // ACTIONS
    fetchWallContest,
    updatePostLike,
    updatePostSocial,
    moreWallContestPosts,
    fetchWallContestPost,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWallContestsStore, import.meta.hot))
}
