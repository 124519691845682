import type { RouteLocationNormalized } from '#vue-router'

export const useLoadWallContest = () => {
  const wapersStore = useWapersStore()
  const wallContestsStore = useWallContestsStore()
  const slidersStore = useDoNotMissStore()

  return async (route: RouteLocationNormalized) => {
    try {
      await Promise.all([
        wapersStore.fetchUserWapers(),
        wallContestsStore.fetchWallContest({
          slug: route.params.contestName as string,
        }),
        // Fetch data for slider `Do Not Miss`
        slidersStore.fetchDoNotMiss(),
      ])
    } catch (error) {
      console.error('from page', error)
    }
  }
}
