import type { RouteLocationNormalized } from '#vue-router'

export const useLoadWaper = () => {
  const wapersStore = useWapersStore()
  const rankingsStore = useRankingStore()

  return async (route: RouteLocationNormalized) => {
    const nickname = route.params.nickname as string

    if (!nickname) {
      throw createError({ statusCode: 404 })
    }

    await Promise.all([
      wapersStore.fetchWaper({
        nickname,
        isUser: false,
      }),
      rankingsStore.fetchTopWaper(nickname),
    ])
  }
}
