import type { Endpoints } from '~/types'

export const useTournamentsStore = defineStore('tournamentsStore', () => {
  const socialStore = useSocialStore()

  const tournament = ref<Endpoints['GET__TOURNAMENT']>()

  const fetchTournament = async ({ slug }: { slug: string }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__TOURNAMENT']>(
      `${API.GET__TOURNAMENT}/${slug}`,
    )
    const rawData = unref(data)
    if (error.value || !rawData) {
      tournament.value = undefined
      return logError(
        'from actions',
        'There was a problem fetching tournaments: ',
        error,
      )
    }

    tournament.value = rawData

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    socialStore.fetchSocial({
      slug,
      slugType: 'contest',
      comments: formattedComments,
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
      is_following: undefined,
    })
  }

  return {
    // STATE
    tournament,

    // ACTIONS
    fetchTournament,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTournamentsStore, import.meta.hot))
}
