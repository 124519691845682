import type { RouteLocationNormalized } from '#vue-router'

export const useLoadPlayerReviewPostTitle = () => {
  const auth = useAuth()
  const wapersStore = useWapersStore()
  const challengesStore = useChallengesStore()
  const rankingStore = useRankingStore()
  const waperOpinionsStore = useWaperOpinionsStore()
  const slidersStore = useDoNotMissStore()
  const popoutStore = usePopoutStore()

  return async (route: RouteLocationNormalized) => {
    try {
      const { nickname } = auth.data.value ?? {}

      await wapersStore.fetchUserWapers()

      await Promise.all([
        // Fetch data about actual challenges
        challengesStore.fetchMonthlyChallenges(),
        // Fetch data about top ranking
        rankingStore.fetchTop({ nickname }),
        waperOpinionsStore.fetchWaperOpinion({
          slug: route.params.postTitle as string,
          community: route.params.communityName as string,
          category: 'avis-joueurs',
        }),
        // Fetch data for slider `Do Not Miss`
        slidersStore.fetchDoNotMiss(),
        // Fetch data for popout
        popoutStore.fetchPopout(),
      ])
    } catch (error) {
      console.error('from page', error)
    }
  }
}
