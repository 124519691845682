import type { RouteLocationNormalized } from '#vue-router'

export const useLoadGameContest = () => {
  const constestsStore = useContestsStore()
  const actualitiesWAPStore = useActualitiesWAPStore()
  const slidersStore = useDoNotMissStore()

  return async (route: RouteLocationNormalized) => {
    try {
      await Promise.all([
        constestsStore.fetchContest({
          slug: route.params.contestName as string,
        }),
        actualitiesWAPStore.fetchOtherActualities(),
        // Fetch data for slider `Do Not Miss`
        slidersStore.fetchDoNotMiss(),
      ])
    } catch (error) {
      console.error('from page', error)
    }
  }
}
