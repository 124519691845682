import type { RouteLocationNormalized } from '#vue-router'

export const useLoadMedias = () => {
  const { authenticated } = useAuthenticated()
  const mediasStore = useMediasStore()
  const metaContentStore = useMetaContentStore()

  return async (route: RouteLocationNormalized) => {
    try {
      // Keep it first if it need to exclude ids
      if (authenticated.value) {
        await mediasStore.fetchUserMedias()
      }

      await Promise.all([
        mediasStore.fetchMedias({
          page: getPageQuery(route),
        }),
        metaContentStore.fetchTransverseContent({ slug: route.name }),
      ])

      if (mediasStore.getPagesCount === -1) {
        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
