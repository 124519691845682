export const useLoadingStore = defineStore('loadingStore', () => {
  const status = ref(false)

  const toggleStatus = () => {
    status.value = !status.value
  }
  const updateStatus = (value: boolean) => {
    status.value = value
  }
  const isLoading = computed(() => status.value)
  return { status, toggleStatus, updateStatus, isLoading }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoadingStore, import.meta.hot))
}
