import type { RouteLocationNormalized } from '#vue-router'

export const useLoadWapersCornerCommunitySubCategoryPostTitle = () => {
  const auth = useAuth()

  const waperCornersStore = useWaperCornersStore()
  const wapersStore = useWapersStore()
  const challengesStore = useChallengesStore()
  const rankingStore = useRankingStore()
  const slidersStore = useDoNotMissStore()
  const popoutStore = usePopoutStore()

  return async (route: RouteLocationNormalized) => {
    try {
      const { nickname } = auth.data.value ?? {}

      await wapersStore.fetchUserWapers()

      await Promise.all([
        // Fetch data about actual challenges
        challengesStore.fetchMonthlyChallenges(),
        // Fetch data about top ranking
        rankingStore.fetchTop({ nickname }),
        waperCornersStore.fetchWaperCorner({
          slug: route.params.postTitle as string,
          community: route.params.communityName as string,
          category: 'le-coin-des-wapers',
          subCategory: route.params.subCategoryName as string,
        }),
        // Fetch data for slider `Do Not Miss`
        slidersStore.fetchDoNotMiss(),
        // Fetch data for popout
        popoutStore.fetchPopout(),
      ])
    } catch (error) {
      console.error('from page', error)
    }
  }
}
