import type {
  CustomComment3,
  CustomCommentResponse,
  FormattedCustomComment,
  Social,
  SocialSortReviews,
  SocialUpdate,
} from '~/types'
import { API } from '~/utils/constants'

export const useSocialStore = defineStore('useSocialStore', () => {
  // STATE
  const social = ref<Social>({
    slug: '',
    slugType: '',
    likes_count: 0,
    is_liked: false,
    shares_count: 0,
    comments_count: 0,
    comments: [],
    tempComments: [],
    commentPage: 1,
    has_more_comments: false,
    is_following: false,
    sortComments: 'popularity',
  })

  // MUTATIONS

  const setSocial = (socialActuality: Social) => {
    social.value = { ...socialActuality }
  }

  const updateLikeComment = (update: SocialUpdate) => {
    if (update.idParent == null) {
      const indexComment = social.value.comments.findIndex(
        (comment) => comment.id === update.idComment,
      )
      let likes = social.value.comments[indexComment].likes_count ?? 0

      if (update.status) {
        likes++
      } else {
        likes--
      }

      social.value.comments.splice(indexComment, 1, {
        ...social.value.comments[indexComment],
        is_liked: update.status,
        likes_count: likes,
      })
      return
    }

    const indexParent = social.value.comments.findIndex(
      (comment) => comment.id === update.idParent,
    )

    const children = social.value.comments[indexParent].children ?? []

    const commentIndex = children.findIndex(
      (comment) => comment.id === update.idComment,
    )

    let likesCount = children[commentIndex].likes_count ?? 0

    if (update.status) {
      likesCount++
    } else {
      likesCount--
    }

    const commentParent = social.value.comments.find(
      (comment) => comment.id === update.idParent,
    )

    if (commentParent && commentParent.children) {
      commentParent.children.splice(commentIndex, 1, {
        ...commentParent.children[commentIndex],
        is_liked: update.status,
        likes_count: likesCount,
      })
    }
    if (!commentParent) return
    social.value.comments.splice(indexParent, 1, {
      ...commentParent,
    })
  }

  const updateLikeContent = (update: boolean) => {
    let likes = social.value.likes_count

    if (update) {
      likes++
    } else {
      likes--
    }

    social.value = {
      ...social.value,
      is_liked: update,
      likes_count: likes,
    }
  }

  const updateFollowWaper = (update: boolean) => {
    social.value = { ...social.value, is_following: update }
  }

  const updateShareContent = () => {
    social.value = {
      ...social.value,
      shares_count: social.value.shares_count + 1,
    }
  }

  const updateSortComments = (update: { sort: SocialSortReviews }) => {
    social.value.sortComments = update.sort
  }

  const setTempComments = () => {
    social.value.tempComments = [...social.value.comments]
  }

  const refreshComments = (update: {
    comments: FormattedCustomComment[]
    first: boolean
  }) => {
    const temp = [...update.comments]

    const rawSocial = unref(social)

    temp.forEach((element, index) => {
      const indCom = rawSocial.tempComments?.findIndex(
        (com) => com.id === element.id,
      )

      if (indCom && indCom !== -1) {
        temp.splice(index, 1, {
          ...element,
          isOpen: !!rawSocial.tempComments?.[indCom].isOpen,
        })
      }
    })

    if (update.first) {
      social.value.comments = [...temp]
    } else {
      social.value.comments.push(...temp)
    }
  }

  const addCommentPage = () => {
    social.value.commentPage = social.value.commentPage + 1
    social.value.comments_count += 1
  }

  const addCommentCount = () => {
    social.value.comments_count += 1
  }

  const updateComments = (update: {
    comments: FormattedCustomComment[]
    has_more_comments: boolean
    newPage: number
  }) => {
    social.value.comments.push(...update.comments)
    social.value.has_more_comments = update.has_more_comments
    social.value.commentPage = update.newPage
  }

  const updateComment = (update: { idComment?: number; status: boolean }) => {
    const indexCom = social.value.comments.findIndex(
      (com) => com.id === update.idComment,
    )

    social.value.comments.splice(indexCom, 1, {
      ...social.value.comments[indexCom],
      isOpen: update.status,
    })
  }

  const updateSubComments = (update: {
    parentId: number
    comments: CustomComment3[]
    has_more_comments: boolean
    newPage: number
  }) => {
    const commentParentIndex = social.value.comments.findIndex(
      (comment) => comment.id === update.parentId,
    )

    const commentParent = social.value.comments[commentParentIndex]

    social.value.comments[commentParentIndex].currentPage = update.newPage
    social.value.comments[commentParentIndex].has_more_comments =
      update.has_more_comments

    if (!commentParent.children) return

    const mergedComments = commentParent.children.concat(update.comments)
    const uniqueComments = mergedComments.filter(
      (comment, index, self) =>
        index === self.findIndex((t) => t.id === comment.id),
    )
    /* 
     // Old implementation, cannot work because indexOf works on object reference
     // and update.comments are new objects coming from the API
     const comments = commentParent.children.concat(update.comments)
     const d = comments.filter((item, index) => comments.indexOf(item) === index)
    */
    social.value.comments[commentParentIndex].children = uniqueComments
  }

  const initSocial = () => {
    social.value = {
      slug: '',
      slugType: '',
      likes_count: 0,
      is_liked: false,
      shares_count: 0,
      comments_count: 0,
      comments: [],
      commentPage: 1,
      has_more_comments: false,
      is_following: false,
    }
  }

  // Getters

  const getSlug = computed(() => social.value.slug)
  const getSortComments = computed(() => social.value.sortComments)
  const getSlugType = computed(() => social.value.slugType)
  const getCommentPage = computed(() => social.value.commentPage)
  const getSubCommentPage = (parentId: number) =>
    computed(() => {
      return social.value.comments.find((comment) => comment.id === parentId)
        ?.currentPage
    })

  const partialPath = computed(() => {
    switch (getSlugType.value) {
      case 'actu-play':
        return 'article'
      case 'challenge':
      case 'event':
      case 'offer':
      case 'tournament':
      case 'contest':
      case 'wallContest':
        return getSlugType.value
      case 'recordContest': {
        return 'speedContest'
      }
      case 'avis-joueurs':
        return 'post/bv-review'
      case 'actualites-communaute':
      case 'astuces':
      case 'avis-medias':
      case 'le-coin-des-wapers':
        return 'post'
      default:
        return
    }
  })

  const getPath = (pathPrefix: string, pathSuffix: string) => {
    if (!partialPath.value) return
    return `${pathPrefix}/${partialPath.value}/${getSlug.value}${pathSuffix}`
  }

  // ACTIONS

  const { authenticated } = useAuthenticated()

  const refreshCommentsAction = async () => {
    setTempComments()

    const pathPrefix = authenticated.value ? '/secure' : ''
    const pathSuffix = '/comment'
    const path = getPath(pathPrefix, pathSuffix)

    if (!path) return

    for (let index = 1; index <= getCommentPage.value; index++) {
      const { error, data } = await useCustomFetch<CustomCommentResponse>(
        path,
        {
          query: {
            page: index,
            sort: getSortComments.value,
          },
        },
      )

      const rawData = unref(data)
      if (error.value || !rawData) {
        return logError(
          'from actions',
          'There was a problem show refreshComments: ',
          error,
        )
      }

      const formattedComments = rawData.results.map((comment) => ({
        ...comment,
        currentPage: 1,
        isOpen: false,
      }))

      refreshComments({
        comments: formattedComments,
        first: index === 1,
      })
    }
  }

  const showMoreContent = async ({ parentId }: { parentId?: number } = {}) => {
    const pathPrefix = authenticated.value ? '/secure' : ''
    const pathSuffix = '/comment'

    const path = getPath(pathPrefix, pathSuffix)
    if (!path) return

    if (parentId) {
      const { error, data } = await useCustomFetch<CustomCommentResponse>(
        path,
        {
          query: {
            page: getSubCommentPage(parentId).value || 0 + 1,
            parent_id: parentId,
            sort: getSortComments.value,
          },
        },
      )

      const rawData = unref(data)
      if (error.value || !rawData) {
        return logError(
          'from actions',
          'There was a problem showing more comments: ',
          error,
        )
      }

      return updateSubComments({
        parentId,
        comments: rawData.results,
        has_more_comments: rawData.has_more_comments ?? false,
        newPage: getSubCommentPage(parentId).value || 0 + 1,
      })
    }
    const { error, data } = await useCustomFetch<CustomCommentResponse>(path, {
      query: {
        page: getCommentPage.value + 1,
        sort: getSortComments.value,
      },
    })

    const rawData = unref(data)

    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem showing more comments: ',
        error,
      )
    }

    const formattedComments = rawData.results.map((comment) => ({
      ...comment,
      currentPage: 1,
      isOpen: false,
    }))

    updateComments({
      comments: formattedComments,
      has_more_comments: rawData.has_more_comments ?? false,
      newPage: getCommentPage.value + 1,
    })
  }

  const postCommentContent = async ({
    content,
  }: {
    content: { parent?: string; content: string }
  }) => {
    const path = getPath('/secure', '/comment')

    if (!path) return {}
    const { error, data } = await useCustomFetch<{ message: string }>(path, {
      body: content,
      method: 'POST',
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      logError('from actions', 'There was a problem posting content: ', error)
      return { data: rawData, error: error.value }
    }

    if (!content.parent && getCommentPage.value > 1) {
      addCommentPage()
    }

    addCommentCount()
    refreshCommentsAction()
    return { data: rawData, error: error.value }
  }

  const { gtag } = useGtag()

  const shareContent = async () => {
    const slug = unref(getSlug)

    gtag('event', 'social', {
      interaction: 'share',
      target_url: slug,
    })

    const pathPrefix = '/secure'
    const pathSuffix = '/share'
    const path = getPath(pathPrefix, pathSuffix)

    if (!path) return

    const { error, data } = await useCustomFetch<{ message: string }>(path, {
      method: 'POST',
    })

    if (!error.value && data.value) {
      updateShareContent()
    } else {
      logError('from actions', 'There was a problem sharing content: ', error)
    }
  }

  const followWaper = async (slugWaper: string) => {
    const { error, data } = await useCustomFetch<{ message: string }>(
      `${API.POST__FOLLOW_WAPER}/${slugWaper}`,
      { method: 'POST' },
    )

    if (!error.value && data.value) {
      return updateFollowWaper(true)
    }
    logError('from actions', 'There was a problem follow waper: ', error)
  }

  const unfollowWaper = async (slugWaper: string) => {
    const { error, data } = await useCustomFetch<{ message: string }>(
      `${API.POST__UNFOLLOW_WAPER}/${slugWaper}`,
      { method: 'POST' },
    )
    if (!error.value && data.value) {
      return updateFollowWaper(false)
    }

    logError('from actions', 'There was a problem unfollow waper: ', error)
  }

  const likeContent = async () => {
    const slug = unref(getSlug)

    gtag('event', 'social', {
      interaction: 'like',
      target_url: slug,
    })

    const pathPrefix = '/secure'
    const pathSuffix = '/like'
    const path = getPath(pathPrefix, pathSuffix)

    if (!path) return

    const { error, data } = await useCustomFetch<{ message: string }>(path, {
      method: 'POST',
    })

    if (!error.value && data.value) {
      return updateLikeContent(true)
    }
    logError('from actions', 'There was a problem liking content: ', error)
  }

  const unlikeContent = async () => {
    const pathPrefix = '/secure'
    const pathSuffix = `/like`

    const path = getPath(pathPrefix, pathSuffix)
    if (!path) return

    const { error, data } = await useCustomFetch<{ message: string }>(path, {
      method: 'DELETE',
    })

    if (!error.value && data.value) {
      return updateLikeContent(false)
    }

    logError('from actions', 'There was a problem unliking content: ', error)
  }

  const unlikeComment = async ({
    idComment,
    idParent,
  }: {
    idComment?: number
    idParent?: number
  }) => {
    const { error, data } = await useCustomFetch<{ message: string }>(
      `/secure/comment/${idComment}/like`,
      { method: 'DELETE' },
    )

    if (!error.value && data.value) {
      return updateLikeComment({
        idComment,
        idParent,
        status: false,
      })
    }

    logError('from actions', 'There was a problem unliking comment: ', error)
  }

  const likeComment = async ({
    idComment,
    idParent,
  }: {
    idComment?: number
    idParent?: number
  }) => {
    const { error, data } = await useCustomFetch<{ message: string }>(
      `/secure/comment/${idComment}/like`,
      { method: 'POST' },
    )

    if (!error.value && data.value) {
      return updateLikeComment({
        idComment,
        idParent,
        status: true,
      })
    }

    logError('from actions', 'There was a problem liking comment: ', error)
  }

  const changeStatusComment = updateComment
  const fetchSocial = setSocial

  return {
    // State
    social,

    // Getters
    getSubCommentPage,

    // Mutations
    setSocial,
    updateLikeContent,
    updateFollowWaper,
    updateShareContent,
    updateSortComments,
    setTempComments,
    refreshComments,
    addCommentPage,
    addCommentCount,
    updateComments,
    updateComment,
    updateSubComments,
    initSocial,
    fetchSocial,

    //Actions
    updateLikeComment,
    refreshCommentsAction,
    showMoreContent,
    shareContent,
    followWaper,
    unfollowWaper,
    likeContent,
    unlikeContent,
    unlikeComment,
    likeComment,
    changeStatusComment,
    postCommentContent,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSocialStore, import.meta.hot))
}
