import type { RouteLocationNormalized } from '#vue-router'

export const useLoadActualitiesWAP = async (route: RouteLocationNormalized) => {
  const actualitiesWapStore = useActualitiesWAPStore()
  const metaContentStore = useMetaContentStore()
  const slidersStore = useDoNotMissStore()

  try {
    await Promise.all([
      actualitiesWapStore.fetchActualities({
        page: getPageQuery(route),
      }),
      // Fetch data for slider `Do Not Miss`
      slidersStore.fetchDoNotMiss(),
      metaContentStore.fetchTransverseContent({ slug: route.name }),
    ])

    if (actualitiesWapStore.getPagesCount === -1) {
      return navigateTo(route.path, { replace: true })
    }
  } catch (error) {
    console.error('from page', error)
  }
}
