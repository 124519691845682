import type {
  CustomPost,
  CustomPost2,
  Endpoints,
  PostWithRoute,
  WithRoute,
} from '~/types'
import { API } from '~/utils/constants'

export const useActualitiesStore = defineStore('actualitiesStore', () => {
  const socialStore = useSocialStore()

  const actualities = ref<PostWithRoute[]>([])

  const communityActualities = ref<PostWithRoute[]>([])
  const communityActualitiesPages = ref(1)
  const communityActualitiesCurrentPage = ref(1)

  const actualitiesPages = ref(1)
  const actualitiesCurrentPage = ref(1)

  const userActualities = ref<PostWithRoute[]>([])

  const actuality = ref<WithRoute<CustomPost2>>()

  const otherActualities = ref<WithRoute<CustomPost>[]>([])

  const bannedIds = ref<number[]>([])

  const getBannedIds = computed(() => bannedIds.value)
  const getActivityId = computed(() => actuality.value?.id)
  const getActivityCommunity = computed(() => actuality.value?.community?.slug)
  const getPagesCount = computed(() => actualitiesPages.value)
  const getCurrentPage = computed(() => actualitiesCurrentPage.value)

  const getRoute = (communitySlug?: string, actualitySlug?: string) => {
    if (!communitySlug || !actualitySlug) return ''
    return `/communautes/${communitySlug}/actualites-communaute/${actualitySlug}`
  }

  const addRouteToActualities = (actualities: CustomPost[]) => {
    return actualities.flatMap((actuality) => {
      if (!actuality.slug || !actuality.community.slug) return []
      const route = getRoute(actuality.community.slug, actuality.slug)
      return { ...actuality, route }
    })
  }

  const fetchActualities = async ({
    page = 1,
    search,
  }: {
    page: number
    search?: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          page,
          banned_ids: getBannedIds.value,
          category_slug: 'actualites-communaute',
          q: search,
          sort: 'date',
          limit: 36,
        },
      },
    )

    if (!error.value && data.value) {
      const actualitiesWithRoute = addRouteToActualities(data.value.results)

      actualities.value = actualitiesWithRoute
      actualitiesPages.value = data.value.pages_count
      actualitiesCurrentPage.value = page
    } else {
      actualities.value = []

      logError(
        'from actions',
        'There was a problem fetching actualities: ',
        error,
      )
    }
  }

  const fetchOtherActualities = async () => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          page: 1,
          banned_ids: getActivityId.value,
          category_slug: 'actualites-communaute',
          limit: 12,
          community_slug: getActivityCommunity.value,
        },
      },
    )

    if (!error.value && data.value) {
      const actualitiesWithRoute = addRouteToActualities(data.value.results)

      otherActualities.value = actualitiesWithRoute
    } else {
      otherActualities.value = []

      logError(
        'from actions',
        'There was a problem fetching other actualities: ',
        error,
      )
    }
  }

  const fetchCommunityActualities = async ({
    page = 1,
    community,
    search,
  }: {
    page: number
    community: string
    search?: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          page,
          category_slug: 'actualites-communaute',
          community_slug: community,
          q: search,
          sort: 'date',
          limit: 36,
        },
      },
    )

    if (!error.value && data.value) {
      const actualitiesWithRoute = addRouteToActualities(data.value.results)

      communityActualities.value = actualitiesWithRoute
      communityActualitiesPages.value = data.value.pages_count
      communityActualitiesCurrentPage.value = page
    } else {
      communityActualities.value = []

      logError(
        'from actions',
        'There was a problem fetching community actualities: ',
        error,
      )
    }
  }

  const fetchUserActualities = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SECURE_POST_COMMUNITY']
    >(API.GET__SECURE_POST_COMMUNITY, {
      query: {
        category_slug: 'actualites-communaute',
      },
    })

    if (error.value) {
      logError(
        'from actions',
        'There was a problem fetching user actualities: ',
        error,
      )
      return
    }

    const postIds: number[] = []
    const formattedUserCommunitiesPosts: PostWithRoute[] = []

    data.value?.forEach((element) => {
      if (element.id) postIds.push(element.id)
      if (element.community.slug && element.slug) {
        formattedUserCommunitiesPosts.push({
          ...element,
          route: getRoute(element.community.slug, element.slug),
        })
      }
    })

    userActualities.value = formattedUserCommunitiesPosts
    bannedIds.value = postIds
  }

  const fetchActuality = async ({
    slug,
    community,
    category,
  }: {
    slug: string
    community: string
    category: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST']>(
      `${API.GET__POST}/${slug}`,
      {
        query: {
          community_slug: community,
          category_slug: category,
        },
      },
    )

    const rawActuality = unref(data)
    if (error.value || !rawActuality) {
      actuality.value = undefined
      logError(
        'from actions',
        'There was a problem fetching actuality: ',
        error,
      )

      throw error.value
    }

    const formattedComments = rawActuality.comments
      ? rawActuality.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    const formattedData = {
      ...rawActuality,
      route: getRoute(rawActuality.community?.slug, rawActuality.slug),
    }

    socialStore.setSocial({
      slug,
      slugType: 'actualites-communaute',
      likes_count: rawActuality.likes_count ?? 0,
      is_liked: rawActuality.is_liked ?? false,
      shares_count: rawActuality.shares_count ?? 0,
      comments_count: rawActuality.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawActuality.has_more_comments ?? false,
      is_following: rawActuality.is_following,
    })

    actuality.value = formattedData
    await fetchOtherActualities()
  }

  return {
    // State
    actualities,
    communityActualities,
    communityActualitiesPages,
    communityActualitiesCurrentPage,
    actualitiesPages,
    actualitiesCurrentPage,
    userActualities,
    actuality,
    otherActualities,
    bannedIds,

    // Getters
    getBannedIds,
    getActivityId,
    getActivityCommunity,
    getPagesCount,
    getCurrentPage,

    // Actions
    fetchActualities,
    fetchOtherActualities,
    fetchCommunityActualities,
    fetchUserActualities,
    fetchActuality,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useActualitiesStore, import.meta.hot))
}
