export default defineNuxtPlugin(() => {
  const allowCookie = useLocalStorage('allow-cookie', {
    cookie1: false,
    cookie2: false,
    cookie3: false,
  })

  if (allowCookie.value.cookie2) {
    const { initialize } = useGtag()
    initialize()
  }

  if (allowCookie.value.cookie3) {
    const pixelConsent = useConsent()
    const metaTracker = usePixelMeta()
    const twitterTracker = usePixelTwitter()
    pixelConsent.grantConsent()
    metaTracker.enable()
    twitterTracker.enable()
  }
})
