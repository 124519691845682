import type { RouteLocationNormalized } from '#vue-router'

export const useLoadGenderCommunity = () => {
  const universesStore = useUniversesStore()
  const communitiesStore = useCommunitiesStore()

  return async (route: RouteLocationNormalized) => {
    try {
      await Promise.all([
        universesStore.fetchOtherUniverses(route.params.genderName as string),
        communitiesStore.fetchGenderCommunities({
          page: getPageQuery(route),
          gender: route.params.genderName as string,
          limit: 60,
          search: '',
        }),
        communitiesStore.fetchGenderInfos(route.params.genderName as string),
      ])

      if (communitiesStore.getGenderPagesCount === -1) {
        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
