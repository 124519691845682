/*
 * This middleware is used to redirect the user to the /mon-profil page
 * if he is a temporary user. Once on the /mon-profil page, the edit profile
 * form will be displayed to the user.
 */

export default defineNuxtRouteMiddleware(async (to) => {
  if (!useIsTempUser().value) return

  if (to.path !== '/mon-profil') {
    return navigateTo('/mon-profil')
  }
})
