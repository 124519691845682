import type { Endpoints } from '~/types'

export const useDoNotMissStore = defineStore('doNotMissStore', () => {
  const doNotMiss = ref<Endpoints['GET__SLIDER_DO_NOT_MISS']>([])

  const fetchDoNotMiss = async () => {
    if (doNotMiss.value?.length) return
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SLIDER_DO_NOT_MISS']
    >(API.GET__SLIDER_DO_NOT_MISS)

    const rawData = unref(data)
    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching do not miss: ',
        error,
      )
    }

    doNotMiss.value = rawData
  }

  return {
    // STATE
    doNotMiss,

    // ACTIONS
    fetchDoNotMiss,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDoNotMissStore, import.meta.hot))
}
