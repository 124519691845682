import type { SessionData } from '#auth'

export const useAuthenticated = () => {
  const auth = useAuth()
  return {
    authenticated: computed(() => {
      return (
        auth.status.value === 'authenticated' ||
        // Happen when the session is being refreshed (ex: getSession())
        (!!auth.data.value && auth.status.value === 'loading')
      )
    }),
  }
}

export const useAuthenticatedTemp = () => {
  const { authenticated } = useAuthenticated()
  const tempJWT = useTempJWTCookie()
  return {
    authenticatedTemp: computed(() => authenticated.value || !!tempJWT.value),
  }
}

export const finalizeUser = async () => {
  const tempJWT = useTempJWTCookie()
  const tempUser = useTempUser()

  if (!tempJWT.value || !tempUser.value) {
    return
  }

  const user = await getUser(tempJWT.value)

  if (user.nickname && user.birthday && user.civility) {
    const { setToken } = useAuthState()
    setToken(tempJWT.value)

    tempUser.value = undefined
    tempJWT.value = undefined
    return useAuth().getSession({ force: true })
  }
}
const getTempSession =
  (auth: ReturnType<typeof useAuth>) =>
  async (options?: { force: boolean }) => {
    const tempJWT = useTempJWTCookie()
    const tempUser = useTempUser()

    if (auth.token.value) {
      const user = await auth.getSession(options)
      return user
    }

    if (
      (tempJWT.value && tempUser.value && !options?.force) ||
      !tempJWT.value
    ) {
      return
    }

    const user = await getUser(tempJWT.value)
    tempUser.value = user
    return tempUser
  }

const allowedTempEndpoints = {
  '/secure/update_profile': true,
  '/secure/update_password': true,
  '/secure/update_displayed_badge': true,
}

const allowedTempEndpointsStartWith = [
  '/user/profile/',
  '/secure/notification/',
]

const getAuthHeader = ({
  authenticated,
  isTemp,
  token,
  url,
}: {
  authenticated: ComputedRef<boolean>
  isTemp: ComputedRef<boolean>
  token: ComputedRef<string | null>
  url: string
}) => {
  if (!authenticated.value || !token.value) {
    return undefined
  }

  if (
    isTemp.value &&
    !(url in allowedTempEndpoints) &&
    !allowedTempEndpointsStartWith.some((allowed) => url.startsWith(allowed))
  ) {
    return undefined
  }

  const bearer = token.value.startsWith('Bearer ')
    ? token.value
    : `Bearer ${token.value}`

  return {
    Authorization: bearer,
  }
}

export const useTempJWTCookie = () =>
  useCookie<string | undefined>('tempJWT', {
    maxAge: 60 * 60 * 27.5,
    secure: true,
    sameSite: 'strict',
  })

export const useTempUser = () => useState<SessionData | undefined>('tempUser')

export const useIsTempUser = () =>
  computed(() => !!useTempUser().value || !!useTempJWTCookie().value)

export const useTempAuth = () => {
  const tempJWT = useTempJWTCookie()
  const tempUser = useTempUser()

  const auth = useAuth()
  const user = computed(() => auth.data.value ?? tempUser.value)
  const { authenticated } = useAuthenticated()

  const isTemp = computed(() => !!tempUser.value || !!tempJWT.value)

  const logout = async () => {
    const cookieLogin = useCookie('connected')
    cookieLogin.value = undefined

    useSocialStore().initSocial()

    if (isTemp.value) {
      tempJWT.value = undefined
      tempUser.value = undefined

      return useRouter().push('/')
    }

    return auth.signOut({ callbackUrl: '/' })
  }

  const token = computed(() => tempJWT.value || auth.token.value)

  const status = computed(() => {
    if (isTemp.value) {
      return 'authenticated'
    }
    return auth.status.value
  })

  return {
    authenticated: computed(() => authenticated.value || isTemp.value),
    user,
    data: user,
    tempJWT,
    isTemp,
    logout,
    getTempSession: getTempSession(auth),
    token,
    status,
    getAuthHeader,
  }
}
