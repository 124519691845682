import type { RouteLocationNormalized } from '#vue-router'

export const useLoadActus = () => {
  const { authenticated } = useAuthenticated()
  const actualitiesStore = useActualitiesStore()
  const metaContentStore = useMetaContentStore()

  return async (route: RouteLocationNormalized) => {
    try {
      // Keep it first if it need to exclude ids
      if (authenticated.value) {
        await actualitiesStore.fetchUserActualities()
      }

      await Promise.all([
        actualitiesStore.fetchActualities({
          page: getPageQuery(route),
        }),
        metaContentStore.fetchTransverseContent({ slug: route.name }),
      ])

      if (actualitiesStore.getPagesCount === -1) {
        console.info(
          `Redirect to previous route from ${route.path} in useLoadActus`,
        )

        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
