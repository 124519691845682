import type {
  CustomCommunity4,
  CustomCommunityWithRoute,
  CustomGameType,
  Endpoints,
} from '~/types'
import { API } from '~/utils/constants'

export const useCommunitiesStore = defineStore('communitiesStore', () => {
  const { token } = useAuth()

  const communities = ref<CustomCommunityWithRoute[]>([])
  const communitiesPagesGame = ref(1)
  const communitiesPagesService = ref(1)
  const communitiesPagesConsole = ref(1)
  const communitiesPagesFree = ref(1)
  const community = ref<CustomCommunity4>()
  const userCommunities = ref<CustomCommunityWithRoute[]>([])
  const genderCommunities = ref<CustomCommunityWithRoute[]>([])
  const genderCommunitiesPages = ref(1)
  const genderCommunitiesCurrentPage = ref(1)
  const genderInfos = ref<CustomGameType>()
  const genderCurrentPage = ref(1)
  const allGenderCommunities = ref<Endpoints['GET__ALL_GENDER_COMMUNITY']>([])
  const typeCommunities = ref<CustomCommunityWithRoute[]>([])
  const typeCommunitiesPages = ref(1)
  const typeCommunitiesCurrentPage = ref(1)

  const getCommunityBySlug = (slug: string) =>
    computed(() =>
      communities.value.find((community) => community.slug === slug),
    )

  const getGenderPagesCount = computed(() => genderCommunitiesPages.value)

  const getGenderCurrentPage = () =>
    computed(() => genderCommunitiesCurrentPage.value)

  const getTypePagesCount = computed(() => typeCommunitiesPages.value)

  const getTypeCurrentPage = () =>
    computed(() => typeCommunitiesCurrentPage.value)

  const setCommunities = (newCommunities: CustomCommunityWithRoute[]) => {
    communities.value = newCommunities
  }

  const pushCommunities = (newCommunities: CustomCommunityWithRoute[]) => {
    const c = communities.value.concat(newCommunities)
    const d = c.filter((item, pos) => c.indexOf(item) === pos)
    communities.value = d
  }

  const setGenderCommunities = (communities: CustomCommunityWithRoute[]) => {
    genderCommunities.value = communities
  }

  const setAllGenderCommunities = (
    communities: Endpoints['GET__ALL_GENDER_COMMUNITY'],
  ) => {
    allGenderCommunities.value = communities
  }

  const setUserCommunities = (communities: CustomCommunityWithRoute[]) => {
    userCommunities.value = communities
  }

  const setCommunitiesPagesGame = (communitiesPages: number) => {
    communitiesPagesGame.value = communitiesPages
  }

  const setCommunitiesPagesConsole = (communitiesPages: number) => {
    communitiesPagesConsole.value = communitiesPages
  }

  const setCommunitiesPagesService = (communitiesPages: number) => {
    communitiesPagesService.value = communitiesPages
  }

  const setCommunitiesPagesFree = (communitiesPages: number) => {
    communitiesPagesFree.value = communitiesPages
  }

  const setGenderCommunitiesPages = (communitiesPages: number) => {
    genderCommunitiesPages.value = communitiesPages
  }

  const setGenderCurrentPage = (page: number) => {
    genderCurrentPage.value = page
  }

  const setCommunity = (newCommunity?: CustomCommunity4) => {
    community.value = newCommunity
  }

  const setGenderInfos = (infos?: CustomGameType) => {
    genderInfos.value = infos
  }

  const updateFollowCommunity = (update: boolean) => {
    if (!community.value) return
    community.value = { ...community.value, is_following: update }
  }

  const setTypeCommunities = (communities: CustomCommunityWithRoute[]) => {
    typeCommunities.value = communities
  }

  const setTypeCommunitiesPages = (count: number) => {
    typeCommunitiesPages.value = count
  }

  const setTypeCommunitiesCurrentPage = (page: number) => {
    typeCommunitiesCurrentPage.value = page
  }

  const fetchGenderInfos = async (gender: string) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__COMMUNITY_GENDER_INFOS']
    >(`${API.GET__COMMUNITY_GENDER_INFOS}${gender}`)

    if (!error.value && data.value) {
      setGenderInfos(data.value)
    } else {
      setGenderInfos()
      console.error(
        'from actions',
        'There was a problem fetching gender communities: ',
        error,
      )
    }
  }
  const fetchGenderCommunities = async ({
    page = 1,
    search = '',
    gender,
    limit,
  }: {
    page: number
    search: string
    gender: string
    limit: number
  }) => {
    const { data: communitySearch, error } = await useCustomFetch<
      Endpoints['GET__COMMUNITY_SEARCH']
    >(API.GET__COMMUNITY_SEARCH, {
      query: {
        page,
        game_type: gender,
        limit,
        q: search,
      },
    })
    const rawCommunitySearch = unref(communitySearch)
    if (error.value || !rawCommunitySearch) {
      setGenderCommunities([])
      return logError(
        'from actions',
        'There was a problem fetching gender communities: ',
        error,
      )
    }

    const formattedData = rawCommunitySearch.results.map((element) => ({
      ...element,
      route: `/communautes/${element.slug}`,
    }))

    setGenderCommunities(formattedData)
    setGenderCommunitiesPages(rawCommunitySearch.pages_count)
    setGenderCurrentPage(page)
    const { data: allGenderCommunities, error: error2 } = await useCustomFetch<
      Endpoints['GET__ALL_GENDER_COMMUNITY']
    >(`${API.GET__ALL_GENDER_COMMUNITY}${gender}`)

    const rawAllGenderCommunities = unref(allGenderCommunities)
    if (error2.value || !rawAllGenderCommunities) {
      return logError(
        'from actions',
        'There was a problem fetching all gender communities: ',
        error,
      )
    }
    setAllGenderCommunities(rawAllGenderCommunities)
  }

  const fetchTypeCommunities = async ({
    page = 1,
    type,
    limit,
    search = '',
  }: {
    page: number
    type: string
    limit: number
    search: string
  }) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__COMMUNITY_SEARCH']
    >(API.GET__COMMUNITY_SEARCH, {
      query: {
        page,
        type,
        limit,
        q: search,
      },
    })

    if (!error.value && data.value) {
      const formattedData = data.value.results.map((element) => ({
        ...element,
        route: `/communautes/${element.slug}`,
      }))

      setTypeCommunities(formattedData)
      setTypeCommunitiesPages(data.value.pages_count)
      setTypeCommunitiesCurrentPage(page)
    } else {
      setTypeCommunities([])
      console.error(
        'from actions',
        'There was a problem fetching type communities: ',
        error,
      )
    }
  }

  const fetchCommunities = async ({ page = 1, search: q = '' } = {}) => {
    const { data, error } = await useAsyncData('fetchCommunities', async () => {
      const [gameResponse, consoleResponse, serviceResponse, freeResponse] =
        await Promise.all([
          myFetch<Endpoints['GET__COMMUNITY_SEARCH']>(
            API.GET__COMMUNITY_SEARCH,
            {
              query: {
                page,
                q,
                type: 'jeu',
              },
            },
          ),
          myFetch<Endpoints['GET__COMMUNITY_SEARCH']>(
            API.GET__COMMUNITY_SEARCH,
            {
              query: {
                page,
                q,
                type: 'console',
              },
            },
          ),
          myFetch<Endpoints['GET__COMMUNITY_SEARCH']>(
            API.GET__COMMUNITY_SEARCH,
            {
              query: {
                page,
                q,
                type: 'service',
              },
            },
          ),
          myFetch<Endpoints['GET__COMMUNITY_SEARCH']>(
            API.GET__COMMUNITY_SEARCH,
            {
              query: {
                page,
                q,
                type: 'libre',
              },
            },
          ),
        ])

      return {
        gameResponse,
        consoleResponse,
        serviceResponse,
        freeResponse,
      }
    })

    if (error.value || !data.value) {
      setCommunities([])
      return logError(
        'from actions',
        'There was a problem fetching communities: ',
        error,
      )
    }

    const formattedData = Object.values(data.value).flatMap((response) =>
      response.results.map((element) => ({
        ...element,
        route: `/communautes/${element.slug}`,
      })),
    )
    setCommunitiesPagesGame(data.value.gameResponse.pages_count)
    setCommunitiesPagesConsole(data.value.consoleResponse.pages_count)
    setCommunitiesPagesService(data.value.serviceResponse.pages_count)
    setCommunitiesPagesFree(data.value.freeResponse.pages_count)
    setCommunities(formattedData)
  }

  const fetchCommunity = async ({ slug }: { slug: string }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__COMMUNITY']>(
      `${API.GET__COMMUNITY}/${slug}`,
    )
    if (!error.value && data.value) {
      const formattedData = {
        ...data.value,
        route: `/communautes/${data.value.slug}`,
      }

      setCommunity(formattedData)
    } else {
      setCommunity()
      console.error(
        'from actions',
        'There was a problem fetching community: ',
        error,
      )
    }
  }

  const followCommunity = async (slug: string) => {
    const { data, error } = await useCustomFetch(
      `${API.POST__FOLLOW_COMMUNITY}/${slug}`,
      { method: 'POST' },
    )
    if (!error.value && data.value) {
      updateFollowCommunity(true)
    } else {
      console.error(
        'from actions',
        'There was a problem following community: ',
        error,
      )
    }
  }

  const unfollowCommunity = async (slug: string) => {
    const { data, error } = await useCustomFetch(
      `${API.POST__UNFOLLOW_COMMUNITY}/${slug}`,
      { method: 'POST' },
    )
    if (!error.value && data.value) {
      updateFollowCommunity(false)
    } else {
      console.error(
        'from actions',
        'There was a problem unfollowing community: ',
        error,
      )
    }
  }

  const fetchUserNotCommunitiesType = async ({
    page = 1,
    type = '',
  }: {
    page: number
    type: string
  }) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SECURE_COMMUNITY_NOT_FOLLOWING']
    >(API.GET__SECURE_COMMUNITY_NOT_FOLLOWING, {
      query: {
        page,
        type,
      },
    })
    if (!error.value && data.value) {
      const formattedData = data.value.results.map((element) => ({
        ...element,
        route: `/communautes/${element.slug}`,
      }))

      pushCommunities(formattedData)
    } else {
      pushCommunities([])
      console.error(
        'from actions',
        'There was a problem fetching user not communities type: ',
        error,
      )
    }
  }

  const fetchCommunitiesType = async ({
    page = 1,
    type = '',
    search = '',
  }: {
    page: number
    type: string
    search: string
  }) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__COMMUNITY_SEARCH']
    >(API.GET__COMMUNITY_SEARCH, {
      query: {
        q: search,
        page,
        type,
      },
    })
    if (!error.value && data.value) {
      const formattedData = data.value.results.map((element) => ({
        ...element,
        route: `/communautes/${element.slug}`,
      }))

      pushCommunities(formattedData)
    } else {
      pushCommunities([])
      console.error(
        'from actions',
        'There was a problem fetching communitie types: ',
        error,
      )
    }
  }

  const fetchUserNotCommunities = async (page = 1) => {
    const { data, error } = await useAsyncData(
      'communitiesNotFollowed',
      async () => {
        const headers: Record<string, string> = {}
        if (token.value) {
          headers['Authorization'] = token.value
        }
        const [gameResponse, consoleResponse, serviceResponse, freeResponse] =
          await Promise.all([
            myFetch<Endpoints['GET__SECURE_COMMUNITY_NOT_FOLLOWING']>(
              API.GET__SECURE_COMMUNITY_NOT_FOLLOWING,
              {
                query: {
                  page,
                  type: 'jeu',
                },
                headers,
              },
            ),
            myFetch<Endpoints['GET__SECURE_COMMUNITY_NOT_FOLLOWING']>(
              API.GET__SECURE_COMMUNITY_NOT_FOLLOWING,
              {
                query: {
                  page,
                  type: 'console',
                },
                headers,
              },
            ),
            myFetch<Endpoints['GET__SECURE_COMMUNITY_NOT_FOLLOWING']>(
              API.GET__SECURE_COMMUNITY_NOT_FOLLOWING,
              {
                query: {
                  page,
                  type: 'service',
                },
                headers,
              },
            ),
            myFetch<Endpoints['GET__SECURE_COMMUNITY_NOT_FOLLOWING']>(
              API.GET__SECURE_COMMUNITY_NOT_FOLLOWING,
              {
                query: {
                  page,
                  type: 'libre',
                },
                headers,
              },
            ),
          ])

        return {
          gameResponse,
          consoleResponse,
          serviceResponse,
          freeResponse,
        }
      },
    )

    if (!error.value && data.value) {
      const responses = Object.values(data.value)

      const formattedData = responses.flatMap((response) =>
        response.results.map((element) => ({
          ...element,
          route: `/communautes/${element.slug}`,
        })),
      )
      setCommunities(formattedData)
      setCommunitiesPagesGame(data.value.gameResponse.pages_count)
      setCommunitiesPagesConsole(data.value.consoleResponse.pages_count)
      setCommunitiesPagesService(data.value.serviceResponse.pages_count)
      setCommunitiesPagesFree(data.value.freeResponse.pages_count)
    } else {
      setCommunities([])
      console.error(
        'from actions',
        'There was a problem fetching not user communities: ',
        error,
      )
    }
  }

  const fetchUserCommunities = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SECURE_COMMUNITY_FOLLOWING']
    >(API.GET__SECURE_COMMUNITY_FOLLOWING)
    if (!error.value && data.value) {
      const formattedData = data.value.results.map((element) => ({
        ...element,
        route: `/communautes/${element.slug}`,
      }))

      setUserCommunities(formattedData)
    } else {
      setUserCommunities([])
      console.error(
        'from actions',
        'There was a problem fetching user communities: ',
        error,
      )
    }
  }

  return {
    // State
    communities,
    communitiesPagesGame,
    communitiesPagesService,
    communitiesPagesConsole,
    communitiesPagesFree,
    community,
    userCommunities,
    genderCommunities,
    genderCommunitiesPages,
    genderCommunitiesCurrentPage,
    genderInfos,
    genderCurrentPage,
    allGenderCommunities,
    typeCommunities,
    typeCommunitiesPages,
    typeCommunitiesCurrentPage,

    // Getters
    getCommunityBySlug,
    getGenderPagesCount,
    getGenderCurrentPage,
    getTypePagesCount,
    getTypeCurrentPage,

    // Mutations
    setCommunities,
    pushCommunities,
    setGenderCommunities,
    setAllGenderCommunities,
    setUserCommunities,
    setCommunitiesPagesGame,
    setCommunitiesPagesConsole,
    setCommunitiesPagesService,
    setCommunitiesPagesFree,
    setGenderCommunitiesPages,
    setGenderCurrentPage,
    setCommunity,
    setGenderInfos,
    updateFollowCommunity,
    setTypeCommunities,
    setTypeCommunitiesPages,
    setTypeCommunitiesCurrentPage,

    // Actions
    fetchGenderInfos,
    fetchGenderCommunities,
    fetchTypeCommunities,
    fetchCommunities,
    fetchCommunity,
    followCommunity,
    unfollowCommunity,
    fetchUserNotCommunitiesType,
    fetchCommunitiesType,
    fetchUserNotCommunities,
    fetchUserCommunities,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCommunitiesStore, import.meta.hot))
}
