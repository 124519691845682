<template>
  <NuxtLayout>
    <div class="error-page">
      <div
        v-if="statusCode === 404"
        class="not-found"
      >
        <img
          src="/img/404.jpg"
          alt="image 404"
        >
        <div class="layer" />
        <div class="content">
          <div class="display">
            <div>
              <h2>Désolé</h2>
              <p>
                La page que vous demandez n’existe pas ou est introuvable.
                <br >Mais il vous reste tant de choses à découvrir 😉
              </p>
            </div>
            <ButtonSimple
              route="/"
              label="Retour à l'accueil"
            />
          </div>
          <h1>404</h1>
        </div>
      </div>
      <div v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90"
          height="90"
          fill="#DBE1EC"
          viewBox="0 0 48 48"
        >
          <path
            d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"
          />
        </svg>
        <div class="title">{{ message }}</div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    error: {
      url: string
      statusCode: number
      statusMessage: string
      message: string
      description: string
      data: unknown
    } | null
  }>(),
  {
    error: null,
  },
)

const statusCode = computed(() => {
  return (props.error && props.error.statusCode) || 500
})

const message = computed(() => {
  if (statusCode.value === 404) {
    return 'Page introuvable'
  }
  return props.error ? props.error.message : ''
})

useHead({
  title: message,
})
</script>

<style lang="scss" scoped>
.error-page {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 90vh;

  .not-found {
    width: 100%;
    height: 100%;
    color: $white;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.2);
    }

    .content {
      position: relative;
      z-index: 1;
      padding: 5.5rem 8rem;

      @media screen and (max-width: 700px) {
        padding: 5.5rem 2.5rem;
      }

      @media screen and (max-width: 375px) {
        padding: 2.5rem;
      }
    }

    .display {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 72.1rem;
      }

      > div {
        margin-bottom: 2rem;

        @media (min-width: 1024px) {
          margin-bottom: 0;
        }
      }
    }

    h1 {
      font-family: 'SST Condensed';
      font-size: 50rem;
      font-weight: bold;
      line-height: 50rem;
      pointer-events: none;

      @media screen and (max-width: 1023px) {
        font-size: 30rem;
        line-height: 30rem;
      }

      @media screen and (max-width: 700px) {
        font-size: 20rem;
        line-height: 20rem;
      }

      @media screen and (max-width: 375px) {
        font-size: 15rem;
        line-height: 15rem;
      }
    }

    h2 {
      margin-bottom: 1rem;
      font-size: 2rem;
      font-weight: bold;
      line-height: 2.7rem;
      letter-spacing: 0;
      text-transform: none;
    }

    p {
      line-height: 1.7rem;

      @media screen and (max-width: 375px) {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
    }
  }
}
</style>
