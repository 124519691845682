import type { RouteLocationNormalized } from '#vue-router'

export const useLoadMoreWapers = () => {
  const wapersStore = useWapersStore()

  return async (route: RouteLocationNormalized) => {
    try {
      // Keep it first if it need to exclude ids

      await wapersStore.fetchUserWapers()

      await wapersStore.fetchWapers({
        page: getPageQuery(route),
      })

      if (wapersStore.getPagesCount === -1) {
        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
