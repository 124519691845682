import revive_payload_client_ePDJpbHvz4 from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_w3Tf2dwbJu from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_EDhpbvUUYF from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tcrLSK9ess from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.9_sass@1.77_6hbdrqkgehasglbhogv2iozjjy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_fAgPBgD1eU from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xYeXbYr0TJ from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XN7AoQlPrV from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5Ss3ofFJIA from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_S7eVCrOStV from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.19.0_typescript@5.4.5_vue@3.4.33_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/web/wap/_online/vue_src/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_LTkAYOhrjR from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import multiTracker_client_h6KZRTE1oL from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt-multi-tracker@0.3.3_magicast@0.3.4_rollup@4.19.0/node_modules/nuxt-multi-tracker/dist/runtime/plugins/multiTracker.client.mjs";
import plugin_1rwe5nOlZc from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt-aos@1.2.5_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.9_sass@1.77.8_terser@5.31.3_/node_modules/nuxt-aos/dist/runtime/plugin.js";
import plugin_RldLpsytTG from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+core@7.24.9_reac_ps2glc62svxvlwipgratgnvyom/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_client_w7oB14NNUW from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt-gtag@2.0.7_magicast@0.3.4_rollup@4.19.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import nuxt_plugin_ZkQP7d09M3 from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/vue-recaptcha@3.0.0-alpha.6_magicast@0.3.4_rollup@4.19.0_vue@3.4.33_typescript@5.4.5_/node_modules/vue-recaptcha/dist/nuxt-plugin.mjs";
import facebook_sdk_client_eVDmfThQ43 from "/home/web/wap/_online/vue_src/nuxt/plugins/facebook-sdk.client.ts";
import gtag_client_Zw8EQXNVTz from "/home/web/wap/_online/vue_src/nuxt/plugins/gtag.client.ts";
import veProgress_client_ll28zSJI7m from "/home/web/wap/_online/vue_src/nuxt/plugins/veProgress.client.ts";
import vue_final_modal_pML93k5qcp from "/home/web/wap/_online/vue_src/nuxt/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_ePDJpbHvz4,
  unhead_w3Tf2dwbJu,
  router_EDhpbvUUYF,
  _0_siteConfig_tcrLSK9ess,
  payload_client_fAgPBgD1eU,
  navigation_repaint_client_xYeXbYr0TJ,
  check_outdated_build_client_XN7AoQlPrV,
  chunk_reload_client_5Ss3ofFJIA,
  plugin_vue3_S7eVCrOStV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LTkAYOhrjR,
  multiTracker_client_h6KZRTE1oL,
  plugin_1rwe5nOlZc,
  plugin_RldLpsytTG,
  plugin_client_w7oB14NNUW,
  nuxt_plugin_ZkQP7d09M3,
  facebook_sdk_client_eVDmfThQ43,
  gtag_client_Zw8EQXNVTz,
  veProgress_client_ll28zSJI7m,
  vue_final_modal_pML93k5qcp
]