export const useLoadCommunities = () => {
  const { authenticated } = useAuthenticated()
  const universesStore = useUniversesStore()
  const communitiesStore = useCommunitiesStore()

  return async () => {
    const promises = [universesStore.fetchUniverses()]

    if (authenticated.value) {
      promises.push(
        communitiesStore.fetchUserCommunities(),
        communitiesStore.fetchUserNotCommunities(),
      )
    } else {
      promises.push(communitiesStore.fetchCommunities())
    }

    await Promise.all(promises)
  }
}
