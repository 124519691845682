import type {
  Category,
  CustomPost2,
  Endpoints,
  PostWithRoute,
  WithRoute,
} from '~/types'

export const useTricksStore = defineStore('tricksStore', () => {
  const socialStore = useSocialStore()

  // STATE
  const tricks = ref<PostWithRoute[]>([])
  const tricksPages = ref(1)
  const tricksCurrentPage = ref(1)
  const otherTricks = ref<PostWithRoute[]>([])
  const userTricks = ref<PostWithRoute[]>([])
  const trick = ref<WithRoute<CustomPost2>>()
  const tricksSubcategoriesPages = ref(1)
  const tricksSubcategoriesList = ref<Category[]>([])
  const bannedIds = ref<number[]>([])
  const communityTricks = ref<PostWithRoute[]>([])
  const communityTricksPages = ref(1)
  const communityTricksCurrentPage = ref(1)
  const communityTricksSubcategoriesList = ref<Category[]>([])
  const communityTricksSubcategories = ref<PostWithRoute[]>([])
  const communityTricksSubcategoriesPages = ref(1)
  const communityTricksSubcategoriesCurrentPage = ref(1)

  // GETTERS
  const getBannedIds = computed(() => bannedIds.value)
  const getTrickId = computed(() => trick.value?.id)
  const getTrickCommunity = computed(() => trick.value?.community?.slug)
  const getSubCategoryBySlug = (slug: Ref<string>) =>
    computed(() =>
      communityTricksSubcategoriesList.value.find(
        (subCat) => subCat.slug === slug.value,
      ),
    )
  const getPagesCount = computed(() => tricksPages.value)
  const getCurrentPage = computed(() => tricksCurrentPage.value)
  const getCommunityPagesCount = computed(() => communityTricksPages.value)
  const getCommunityCurrentPage = computed(
    () => communityTricksCurrentPage.value,
  )
  const getCategoryPagesCount = computed(
    () => communityTricksSubcategoriesPages.value,
  )
  const getCategoryCurrentPage = computed(
    () => communityTricksSubcategoriesCurrentPage.value,
  )

  // ACTIONS
  const addContent = (content: PostWithRoute) => {
    content.route = `/communautes/${content.community.slug}/astuces/${
      content.sub_category ? content.sub_category.slug : 'nodata'
    }/${content.slug}`
    tricks.value.push(content)
  }

  const fetchTricks = async ({ page = 1, search = '', filter = '' }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          q: search,
          category_slug: 'astuces',
          sub_category_slug: filter,
          banned_ids: getBannedIds.value,
          page,
          sort: 'date',
          limite: '36',
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      tricks.value = []

      return logError(
        'from actions',
        'There was a problem fetching tricks: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community.slug}/astuces/${
        element.sub_category ? element.sub_category.slug : 'nodata'
      }/${element.slug}`,
    }))

    tricks.value = formattedData
    tricksPages.value = rawData.pages_count
    tricksCurrentPage.value = page
  }

  const fetchTricksSubcategories = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SUBCATEGORIES']
    >(`${API.GET__SUBCATEGORIES}astuces`)
    const rawData = unref(data)
    if (error.value || !rawData) {
      tricksSubcategoriesList.value = []

      return logError(
        'from actions',
        'There was a problem fetching tricks subcategories: ',
        error,
      )
    }

    tricksSubcategoriesList.value = rawData
  }

  const fetchTricksCommunitySubcategories = async (community: string) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SUBCATEGORIES']
    >(`${API.GET__SUBCATEGORIES}astuces`, {
      query: { community_slug: community },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      communityTricksSubcategoriesList.value = []
      return logError(
        'from actions',
        'There was a problem fetching tricks subcategories list: ',
        error,
      )
    }

    communityTricksSubcategoriesList.value = rawData
  }

  const fetchCommunityTricks = async ({
    page = 1,
    community,
    search,
    filter,
  }: {
    page?: number
    community?: string
    search?: string
    filter?: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          q: search,
          category_slug: 'astuces',
          sub_category_slug: filter,
          community_slug: community,
          page,
          limit: '36',
          sort: 'date',
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      communityTricks.value = []
      return logError(
        'from actions',
        'There was a problem fetching astuces',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community.slug}/astuces/${
        element.sub_category ? element.sub_category.slug : 'nodata'
      }/${element.slug}`,
    }))
    communityTricks.value = formattedData
    communityTricksPages.value = rawData.pages_count
    communityTricksCurrentPage.value = page
  }

  const fetchCommunitySubcategoriesTricks = async ({
    page = 1,
    community,
    search,
    subCategory,
  }: {
    page?: number
    community?: string
    search?: string
    subCategory: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          q: search,
          category_slug: 'astuces',
          sub_category_slug: subCategory,
          community_slug: community,
          page,
          limit: '36',
          sort: 'date',
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      communityTricksSubcategories.value = []

      return logError(
        'from actions',
        'There was a problem fetching subcategory astuces: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => {
      return {
        ...element,
        route: `/communautes/${element.community.slug}/astuces/${subCategory}/${element.slug}`,
      }
    })
    communityTricksSubcategories.value = formattedData
    communityTricksSubcategoriesPages.value = rawData.pages_count
    communityTricksSubcategoriesCurrentPage.value = page
  }

  const fetchUserTricks = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SECURE_POST_COMMUNITY']
    >(API.GET__SECURE_POST_COMMUNITY, {
      query: {
        category_slug: 'astuces',
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      userTricks.value = []
      return logError(
        'from actions',
        'There was a problem fetching user tricks: ',
        error,
      )
    }

    const postIds: number[] = []
    const formattedData: PostWithRoute[] = []

    rawData.forEach((element) => {
      if (element.id) postIds.push(element.id)
      formattedData.push({
        ...element,
        route: `/communautes/${element.community.slug}/astuces/${
          element.sub_category ? element.sub_category.slug : 'nodata'
        }/${element.slug}`,
      })
    })

    userTricks.value = formattedData
    bannedIds.value = postIds
  }

  const fetchTrick = async ({
    slug,
    community,
    category,
    subCategory,
  }: {
    slug: string
    community: string
    category: string
    subCategory?: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST']>(
      `${API.GET__POST}/${slug}`,
      {
        query: {
          community_slug: community,
          category_slug: category,
          sub_category_slug: subCategory,
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      trick.value = undefined
      return logError(
        'from actions',
        'There was a problem fetching trick: ',
        error,
      )
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    const formattedData = {
      ...rawData,
      route: `/communautes/${rawData.community?.slug}/${rawData.category.slug}/${rawData.slug}`,
    }

    socialStore.fetchSocial({
      slug,
      slugType: 'astuces',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
      is_following: rawData.is_following ?? false,
    })

    trick.value = formattedData
    await fetchOtherTricks()
  }

  const fetchOtherTricks = async (subCategory = '') => {
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          banned_ids: getTrickId.value,
          category_slug: 'astuces',
          sub_category_slug: subCategory,
          community_slug: getTrickCommunity.value,
          page: '1',
          limit: '12',
          sort: 'date',
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      otherTricks.value = []

      return logError(
        'from actions',
        'There was a problem fetching other tricks: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community.slug}/${element.category.slug}/${
        element.sub_category ? element.sub_category.slug : 'nodata'
      }/${element.slug}`,
    }))
    otherTricks.value = formattedData
  }

  return {
    // STATE
    tricks,
    tricksPages,
    tricksCurrentPage,
    otherTricks,
    userTricks,
    trick,
    tricksSubcategoriesPages,
    tricksSubcategoriesList,
    bannedIds,
    communityTricks,
    communityTricksPages,
    communityTricksCurrentPage,
    communityTricksSubcategoriesList,
    communityTricksSubcategories,
    communityTricksSubcategoriesPages,
    communityTricksSubcategoriesCurrentPage,

    // GETTERS
    getSubCategoryBySlug,
    getPagesCount,
    getCurrentPage,
    getCommunityPagesCount,
    getCommunityCurrentPage,
    getCategoryPagesCount,
    getCategoryCurrentPage,

    // ACTIONS
    addContent,
    fetchTricks,
    fetchTricksSubcategories,
    fetchTricksCommunitySubcategories,
    fetchCommunityTricks,
    fetchCommunitySubcategoriesTricks,
    fetchUserTricks,
    fetchTrick,
    fetchOtherTricks,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTricksStore, import.meta.hot))
}
