import type { RouteLocationNormalized } from '#vue-router'

export const useLoadActuPlay = () => {
  const actualitiesWapStore = useActualitiesWAPStore()
  const wapersStore = useWapersStore()
  const slidersStore = useDoNotMissStore()

  return async (route: RouteLocationNormalized) => {
    await actualitiesWapStore
      .fetchActuality({
        slug: route.params.title as string,
      })
      .catch(createError404)

    await Promise.all([
      wapersStore.fetchUserWapers(),
      // Fetch another posts data
      actualitiesWapStore.fetchOtherActualities({ page: 1 }),
      // Fetch data for slider `Do Not Miss`
      slidersStore.fetchDoNotMiss(),
    ])
  }
}
