import type { Endpoints } from '~/types'

export const useRecordContestsStore = defineStore('recordContestsStore', () => {
  const socialStore = useSocialStore()

  // STATE
  const recordContest = ref<Endpoints['GET__RECORDCONTEST']>()
  const currentPost = ref<Endpoints['GET__RECORDCONTESTTEAM']>()
  const posts = ref<Endpoints['GET__RECORDCONTEST']['sc_posts']>([])
  const postsPages = ref(1)
  const currentPage = ref(1)

  // GETTERS
  const getPosts = computed(() => posts.value)
  const getPostsPage = computed(() => postsPages.value)
  const getCurrentPage = computed(() => currentPage.value)

  // ACTIONS
  const { authenticated } = useAuthenticated()

  const fetchRecordContest = async ({ slug = '' }) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__RECORDCONTEST']
    >(
      `${(authenticated.value && `/secure`) || ''}${
        API.GET__RECORDCONTEST
      }/${slug}`,
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      recordContest.value = undefined
      return logError(
        'from actions',
        'There was a problem fetching recordContest: ',
        error,
      )
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    socialStore.fetchSocial({
      slug,
      slugType: 'recordContest',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
    })

    recordContest.value = rawData
    posts.value = rawData.sc_posts
    currentPage.value = 1
  }

  const updatePostLike = (slug: '', liked: false) => {
    const posts = getPosts.value
    const currentIndex = posts.findIndex((post) => post.slug === slug)

    posts[currentIndex] = {
      ...posts[currentIndex],
      likes_count: liked
        ? posts[currentIndex].likes_count ?? +1
        : posts[currentIndex].likes_count ?? -1,
      is_liked: liked,
    }

    if (recordContest.value) {
      recordContest.value.sc_posts = posts
    }
  }

  const updatePostSocial = (
    slug: '',
    likes_count: 0,
    comments_count: 0,
    is_liked: false,
  ) => {
    const posts = getPosts.value
    const currentIndex = posts.findIndex((post) => post.slug === slug)

    posts[currentIndex] = {
      ...posts[currentIndex],
      likes_count,
      is_liked,
      comments_count,
    }

    if (recordContest.value) {
      recordContest.value.sc_posts = posts
    }
  }

  const moreRecordContestPosts = async ({ slug = '' }) => {
    const nextPage = getCurrentPage.value + 1

    const { data, error } = await useCustomFetch<
      Endpoints['GET__RECORDCONTESTPOSTS']
    >(
      `${
        authenticated.value ? `/secure` : ''
      }${API.GET__RECORDCONTESTPOSTS.replace('$slug', slug)}`,
      {
        query: {
          page: nextPage,
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching moreRecordContestPosts: ',
        error,
      )
    }

    currentPage.value = nextPage
    postsPages.value = rawData.pages_count

    const hasMoreSCPosts = rawData.pages_count > nextPage
    if (
      recordContest.value &&
      recordContest.value.has_more_sc_posts !== hasMoreSCPosts
    ) {
      recordContest.value.has_more_sc_posts = hasMoreSCPosts
    }
    posts.value.push(...rawData.results)
  }

  const fetchRecordContestPost = async ({ slug = '' }) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__RECORDCONTESTTEAM']
    >(
      `${(authenticated.value && `/secure`) || ''}${
        API.GET__RECORDCONTESTTEAM
      }/${slug}`,
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      currentPost.value = undefined
      return logError(
        'from actions',
        'There was a problem fetching recordContestPost: ',
        error,
      )
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    currentPost.value = rawData

    socialStore.fetchSocial({
      slug,
      slugType: 'recordContestPost',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
    })
  }

  return {
    // STATE
    recordContest,
    currentPost,
    posts,
    postsPages,
    currentPage,

    // GETTERS
    getPosts,
    getPostsPage,

    // ACTIONS
    fetchRecordContest,
    updatePostLike,
    updatePostSocial,
    moreRecordContestPosts,
    fetchRecordContestPost,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useRecordContestsStore, import.meta.hot),
  )
}
