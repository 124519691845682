import type { Response } from '@/types/facebook'

type Method = 'get' | 'post' | 'delete'
interface Params {
  accessToken?: string
  fields?: string
  [key: string]: unknown
}

interface FBOptions {
  appId: string
  autoLogAppEvents: boolean
  xfbml: boolean
  version: string
}

interface CallbackResponse {
  first_name: string
  last_name: string
  email: string
}

type Callback = (response: CallbackResponse) => void
declare global {
  interface Window {
    fbAsyncInit: () => void
    FB: {
      init: (options: FBOptions) => void
      AppEvents: {
        logPageView: () => void
      }
      getLoginStatus: (
        callback: (response: Response) => void,
        force?: boolean,
      ) => void
      login: (
        callback: (response: Response) => void,
        params?: { scope?: string },
      ) => void
      api: (
        path: string,
        method?: Method | Params | Callback,
        params?: Params | Callback,
        callback?: Callback,
      ) => void
    }
  }
}

export default defineNuxtPlugin(async () => {
  const runtimeConfig = useRuntimeConfig()
  const { facebookId } = runtimeConfig.public

  const FBOptions = {
    appId: facebookId,
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v8.0',
  }

  const { load } = useScriptTag(
    'https://connect.facebook.net/fr_FR/sdk.js',
    undefined,
    {
      attrs: {
        id: 'facebook-jssdk',
      },
      crossOrigin: 'anonymous',
      manual: true,
    },
  )

  await load()

  const SDK: Window['FB'] = await new Promise((resolve) => {
    window.fbAsyncInit = function onSDKInit() {
      window.FB.init(FBOptions)
      window.FB.AppEvents.logPageView()
      window.dispatchEvent(new Event('fb-sdk-ready'))
      return resolve(window.FB)
    }
  })

  return {
    provide: {
      FB: SDK,
    },
  }
})
