<template>
  <Component
    :is="buttonComponent"
    class="button"
    :to="route"
    :class="[color, outline ? 'button--outline' : '']"
    :disabled="disabled"
    :type="buttonComponent === 'button' ? 'button' : undefined"
  >
    {{ capitalize(label) }}
    <svg
      v-if="outside"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.328 34.43"
    >
      <path
        d="M165.21-130.78l-15.05-15.1a2.178,2.178,0,0,0-3.041,0,2.178,2.178,0,0,0,0,3.041l13.555,13.555L147.119-115.73a2.178,2.178,0,0,0,0,3.041,2.178,2.178,0,0,0,3.041,0l15.05-15.05A2.178,2.178,0,0,0,165.21-130.78Z"
        transform="translate(-146.5 146.5)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  </Component>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'
import { capitalize } from '~/utils/formatHelper'

const props = withDefaults(
  defineProps<{
    label?: string
    route?: string
    color?: string
    outside?: boolean
    outline?: boolean
    disabled?: boolean
    tag?: string
  }>(),
  {
    label: undefined,
    route: undefined,
    color: undefined,
    outside: false,
    outline: false,
    disabled: false,
    tag: 'button',
  },
)

const buttonComponent = computed(() => {
  if (props.route) {
    return NuxtLink
  }
  return props.tag
})
</script>

<style lang="scss" scoped>
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 4.6rem;
  padding: 0 2rem;
  color: $white;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: inherit;
  background: $backgroundBlue;
  border: 1px solid $backgroundBlue;
  transition:
    opacity 0.35s,
    color 0.35s,
    background 0.35s,
    border 0.35s;

  &:disabled,
  &.disabled {
    color: $dark; // #595959;
    background-color: $lightGreyBorder;
    border-color: $lightGreyBorder;
    cursor: not-allowed;
  }

  &.disabled {
    pointer-events: none;
  }

  &:not(:disabled):not(.disabled):hover {
    background-color: #0089d8;
  }

  &.orange {
    &,
    &:not(:disabled):not(.disabled):hover {
      background: $orange;
      border-color: $orange;
    }
  }

  &.white {
    &,
    &:not(:disabled):not(.disabled):hover {
      color: $blue;
      background: $white;
      border-color: $white;
    }
  }

  &.danger {
    &,
    &:not(:disabled):not(.disabled):hover {
      background: $red;
      border-color: $red;
    }
  }

  &.white,
  &.orange,
  &.danger {
    &:not(:disabled):not(.disabled):hover {
      opacity: 0.75;
    }

    &:disabled,
    &.disabled {
      background-color: $lightGreyBorder;
      border-color: $lightGreyBorder;
    }
  }

  svg {
    width: 0.75rem;
    height: 1.33rem;
    margin-left: 1.6rem;
    transform: rotate(90deg);
  }

  &--outline {
    color: $backgroundBlue;
    background-color: transparent;

    &:disabled,
    &.disabled {
      color: $dark;
      background-color: $lightGreyBorder;
    }

    &:not(:disabled):not(.disabled):hover {
      color: $white;
      background-color: $backgroundBlue;
      opacity: 1;
    }

    &.white {
      color: $white;
      background-color: transparent;

      &:not(:disabled):not(.disabled):hover {
        color: $blue;
        background-color: $white;
        opacity: 1;
      }
    }

    &.orange {
      color: $orange;
      background-color: transparent;

      &:not(:disabled):not(.disabled):hover {
        color: $white;
        background-color: $orange;
        opacity: 1;
      }
    }

    &.danger {
      color: $red;
      background-color: transparent;

      &:not(:disabled):not(.disabled):hover {
        color: $white;
        background-color: $red;
        opacity: 1;
      }
    }

    &.white,
    &.orange,
    &.danger {
      &:disabled,
      &.disabled {
        color: $dark;
      }
    }
  }
}

@media screen and (width >= 700px) {
  .button {
    height: 4.8rem;
    padding: 0 2rem;
    font-size: 1.5rem;
    line-height: 4.8rem;
  }
}
</style>
