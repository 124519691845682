import type { RouteLocationNormalized } from 'vue-router'

export const useLoadActusCommuPostTitle = () => {
  const auth = useAuth()
  const wapersStore = useWapersStore()
  const challengesStore = useChallengesStore()
  const rankingStore = useRankingStore()
  const actualitiesStore = useActualitiesStore()
  const slidersStore = useDoNotMissStore()
  const popoutStore = usePopoutStore()

  return async (route: RouteLocationNormalized) => {
    const { nickname } = auth.data.value ?? {}

    await Promise.all([
      actualitiesStore
        .fetchActuality({
          slug: route.params.postTitle as string,
          community: route.params.communityName as string,
          category: 'actualites-communaute',
        })
        .catch(createError404),
      wapersStore.fetchUserWapers(),
      // Fetch data about actual challenges
      challengesStore.fetchMonthlyChallenges(),
      // Fetch data about top ranking
      rankingStore.fetchTop({ nickname }),
      // Fetch data for slider `Do Not Miss`
      slidersStore.fetchDoNotMiss(),
      // Fetch data for popout
      popoutStore.fetchPopout(),
    ])
  }
}
