import type { RouteLocationNormalized } from '#vue-router'

export const useLoadTypeCommunity = () => {
  const communitiesStore = useCommunitiesStore()

  return async (route: RouteLocationNormalized) => {
    try {
      await Promise.all([
        communitiesStore.fetchTypeCommunities({
          page: getPageQuery(route),
          type: route.params.typeName as string,
          limit: 60,
          search: '',
        }),
      ])

      if (communitiesStore.getTypePagesCount === -1) {
        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
