import type { RouteLocationNormalized } from '#vue-router'

export const useLoadOffer = () => {
  const wapersStore = useWapersStore()
  const offersStore = useOffersStore()
  const slidersStore = useDoNotMissStore()

  return async (route: RouteLocationNormalized) => {
    try {
      await Promise.all([
        wapersStore.fetchUserWapers(),
        offersStore.fetchOffer({
          slug: route.params.offerName as string,
        }),
        slidersStore.fetchDoNotMiss(),
      ])
    } catch (error) {
      console.error('from page', error)
    }
  }
}
