import type { RouteLocationNormalized } from '#vue-router'

export const useLoadChallenge = () => {
  const auth = useAuth()
  const { authenticated } = useAuthenticated()
  const challengesStore = useChallengesStore()
  const rankingStore = useRankingStore()
  const wapersStore = useWapersStore()
  const slidersStore = useDoNotMissStore()

  return async (route: RouteLocationNormalized) => {
    try {
      const { nickname } = auth.data.value ?? {}

      // Keep it there to gather banned ids to avoid duplicated items in requests
      if (authenticated.value) {
        await wapersStore.fetchUserWapers()
      }

      await Promise.all([
        // Fetch data about the current challenge
        challengesStore.fetchChallenge({
          slug: route.params.challengeName as string,
        }),
        // Fetch data about actual challenges
        challengesStore.fetchMonthlyChallenges(true),
        // Fetch data about top ranking
        rankingStore.fetchTop({
          nickname,
        }),
        // Fetch data for slider `Do Not Miss`
        slidersStore.fetchDoNotMiss(),
      ])
    } catch (error) {
      console.error('from page', error)
    }
  }
}
