import type { Endpoints } from '~/types'

export const usePopoutStore = defineStore('popoutStore', () => {
  const popout = ref<Endpoints['GET__POPOUT']>()
  const bonusPointsWhenRegistering = ref(false)

  const fetchPopout = async () => {
    if (popout.value?.id) return popout.value

    const { data, error } = await useCustomFetch<Endpoints['GET__POPOUT']>(
      API.GET__POPOUT,
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching popout: ',
        error,
      )
    }

    popout.value = rawData
    return popout.value
  }

  const setBonusPointsWhenRegistering = (value: boolean) => {
    bonusPointsWhenRegistering.value = value
  }

  return {
    // STATE
    popout,
    bonusPointsWhenRegistering,

    // ACTIONS
    fetchPopout,
    setBonusPointsWhenRegistering,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePopoutStore, import.meta.hot))
}
