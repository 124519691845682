<template>
  <div
    v-if="isLoading"
    class="loading-page"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 355.95 85.04"
    >
      <path
        fill="#0072ce"
        class="t"
        d="M47.39.12 0 81.96h94.77ZM17.33 71.98l30.08-52 30.07 52Z"
      />
      <path
        fill="#0072ce"
        class="c"
        d="M135.7 0a42.52 42.52 0 1 0 42.52 42.52A42.52 42.52 0 0 0 135.7 0Zm0 73.78a31.26 31.26 0 1 1 31.27-31.26 31.3 31.3 0 0 1-31.27 31.26Z"
      />
      <path
        fill="#0072ce"
        class="s"
        d="M278.54 4.02v77.41h77.41V4.02Zm10.52 66.89V14.54h56.37v56.37Z"
      />
      <polygon
        fill="#0072ce"
        class="x"
        points="263.49 12.07 255.44 4.02 224.78 34.67 194.13 4.02 186.08 12.07 216.73 42.72 186.08 73.37 194.13 81.42 224.78 50.77 255.44 81.42 263.49 73.37 232.84 42.72 263.49 12.07"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { useLoadingIndicator } from '#app/composables/loading-indicator'

const { isLoading } = useLoadingIndicator()
</script>

<style scoped lang="scss">
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $white;

  svg {
    width: 20rem;
    height: 4.778rem;
  }

  path,
  polygon {
    opacity: 0.2;
    animation: fade-in-out 1.2s infinite ease-in-out;
    fill: #0072ce;

    &.t {
      animation-delay: 0s;
    }

    &.c {
      animation-delay: 0.25s;
    }

    &.x {
      animation-delay: 0.5s;
    }

    &.s {
      animation-delay: 0.75s;
    }
  }

  @keyframes fade-in-out {
    0%,
    80%,
    100% {
      opacity: 0.2;
    }

    40% {
      opacity: 1;
    }
  }
}
</style>
