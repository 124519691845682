import type { RouteLocationNormalized } from '#vue-router'

export const useLoadCorners = () => {
  const { authenticated } = useAuthenticated()
  const waperCornersStore = useWaperCornersStore()
  const metaContentStore = useMetaContentStore()

  return async (route: RouteLocationNormalized) => {
    try {
      // Keep it first if it need to exclude ids
      if (authenticated.value) {
        await waperCornersStore.fetchUserWaperCorners()
      }

      await Promise.all([
        waperCornersStore.fetchWaperCorners({
          page: getPageQuery(route),
        }),
        metaContentStore.fetchTransverseContent({ slug: route.name }),
      ])

      if (waperCornersStore.getPagesCount === -1) {
        console.info(
          `Redirect to previous route from ${route.path} in useLoadActus`,
        )
        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
