export const capitalize = (value?: string) => {
  if (!value) return ''
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const pluralize = (word: string, amount?: number) => {
  return amount && amount > 1 ? `${word}s` : word
}

export const numberToString = (value?: number | string) => {
  if (value == null) return ''
  const numericValue = typeof value === 'string' ? parseFloat(value) : value
  return numericValue.toLocaleString()
}

export const readMore = (
  text: string,
  length: number,
  suffix: string = '...',
) => {
  if (text.length <= length + suffix.length) {
    return text
  }

  return text.substring(0, length) + suffix
}

export const kFormatter = (num?: number) => {
  if (num == null) return ''
  return Math.abs(num) > 999
    ? Math.sign(num) * +(Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num)
}

export const zeroFormatter = (value: number) => {
  return ('00' + value).slice(-2)
}

export const renameWapersCornerCategory = (category: string | undefined) => {
  return String(category).toLowerCase().trim() === 'le coin des wapers'
    ? 'Le feed des players'
    : category
}

export const renameWapersCornerUrl = (url: string | undefined) => {
  return url === 'le-coin-des-wapers' ? 'le-feed-des-players' : url
}

export const replaceWapersCornerSlug = (slug: string) => {
  return slug?.replaceAll('le-coin-des-wapers', 'le-feed-des-players')
}
