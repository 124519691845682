import type { CrossContent, Endpoints, FormattedCrossContent } from '~/types'
import { API } from '~/utils/constants'

export const useCrossContentStore = defineStore('crossContentStore', () => {
  const crossContent = ref<FormattedCrossContent>()
  const referrer = ref('')
  const communitiesMenu = ref<CrossContent['communities']>()

  const getCrossContent = computed(() => crossContent.value)
  const getReferrer = computed(() => referrer.value)

  const setCrossContent = (newCrossContent: FormattedCrossContent) => {
    crossContent.value = newCrossContent
  }

  const setReferrer = (newReferrer: string) => {
    referrer.value = newReferrer
  }

  const setCommunitiesMenu = (menu: CrossContent['communities']) => {
    communitiesMenu.value = menu
  }

  const fetchCrossContent = async () => {
    if (crossContent.value) return

    const { data, error } = await useCustomFetch<Endpoints['GET_CROSSCONTENT']>(
      API.GET_CROSSCONTENT,
    )
    const rawData = unref(data)

    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem fetching crossContent: ',
        error,
      )
    }

    const { popin, rtbSLider, benefitsPage, connectedPopin, communities } =
      rawData

    const formattedData = {
      menuInfos: {
        intro: popin.menu_title,
        image_mobile: popin.mobile_image_menu,
        image_desktop: popin.desktop_image_menu,
      },
      bannerInfos: {
        id: popin.id,
        intro: popin.content,
        image_mobile: popin.mobile_image,
        image_desktop: popin.desktop_image,
      },
      bannerInfosConnected: {
        id: connectedPopin?.id,
        intro: connectedPopin?.content,
        supTitle: connectedPopin?.sur_title,
        image_mobile: connectedPopin?.mobile_image,
        image_desktop: connectedPopin?.desktop_image,
        link_button: connectedPopin?.link_button,
        link_url: connectedPopin?.link_url,
        display_start: connectedPopin
          ? connectedPopin.display_start
          : '1995-12-17T03:24:00',
        display_end: connectedPopin
          ? connectedPopin.display_end
          : '1995-12-17T03:24:00',
      },
      benefitsPage,
      rtbSLider,
    }

    setCrossContent(formattedData)
    setCommunitiesMenu(communities)
  }

  return {
    // State
    crossContent,
    referrer,
    communitiesMenu,

    // Getters
    getCrossContent,
    getReferrer,

    // Mutations
    setCrossContent,
    setReferrer,
    setCommunitiesMenu,

    // Actions
    fetchCrossContent,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCrossContentStore, import.meta.hot))
}
