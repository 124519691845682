import type { RouteLocationNormalized } from '#vue-router'

export const useLoadTricks = () => {
  const { authenticated } = useAuthenticated()
  const tricksStore = useTricksStore()
  const metaContentStore = useMetaContentStore()

  return async (route: RouteLocationNormalized) => {
    try {
      // Keep it first if it need to exclude ids
      if (authenticated.value) {
        await tricksStore.fetchUserTricks()
      }

      await Promise.all([
        tricksStore.fetchTricks({
          page: getPageQuery(route),
        }),
        tricksStore.fetchTricksSubcategories(),
        metaContentStore.fetchTransverseContent({ slug: route.name }),
      ])

      if (tricksStore.getPagesCount === -1) {
        return navigateTo(route.path, { replace: true })
      }
    } catch (error) {
      console.error('from page', error)
    }
  }
}
