import type { FetchError } from 'ofetch'

type LogError = MaybeRef<FetchError | null | undefined>
type LogErrorOverloads = {
  (locationOrError: LogError): void
  (locationOrError: string, message: string, errorOrMessage: LogError): void
}

export const logError: LogErrorOverloads = (
  locationOrError: LogError | string,
  message?: string,
  errorOrMessage?: LogError,
) => {
  if (typeof locationOrError === 'string') {
    const rawErrorOrMessage = unref(errorOrMessage)
    if (message && rawErrorOrMessage) {
      console.error(locationOrError, message, rawErrorOrMessage)
    }
  }

  console.error(unref(locationOrError))
}
