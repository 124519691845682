import type {
  Endpoints,
  NotificationsResponse,
  CustomNotification,
} from '~/types'

export const useNotificationsStore = defineStore('notificationsStore', () => {
  const notifications = ref<CustomNotification[]>([])
  const more = ref(false)
  const unread = ref<number>()
  const lastId = ref<number>()

  const initStore = ({
    results,
    has_more,
    unread_count,
  }: NotificationsResponse) => {
    notifications.value = results
    lastId.value = results[results.length - 1].id
    more.value = has_more
    unread.value = unread_count
  }

  const setNotifications = ({
    results,
    unread_count,
  }: NotificationsResponse) => {
    notifications.value = [
      ...results.filter((n) => !notifications.value.find((s) => s.id === n.id)),
      ...notifications.value,
    ]
    unread.value = unread_count
  }

  const addNotifications = ({
    results,
    has_more,
    unread_count,
  }: NotificationsResponse) => {
    if (results.length) {
      notifications.value.push(...results)
      lastId.value = results[results.length - 1].id
    }

    more.value = has_more
    unread.value = unread_count
  }

  const setCount = ({ unread_count }: NotificationsResponse) => {
    unread.value = unread_count
  }

  const setUnread = (
    notification: CustomNotification,
    {
      unread_count,
    }: NotificationsResponse | Endpoints['PUT__NOTIFICATION_READ'],
  ) => {
    unread.value = unread_count
    notifications.value = notifications.value.map((n) => {
      if (n.id === notification.id) {
        return {
          ...n,
          read_at: new Date().toISOString(),
        }
      }
      return n
    })
  }

  const setUnreadAll = ({
    unread_count,
  }: Endpoints['PUT__NOTIFICATION_READ_ALL']) => {
    unread.value = unread_count
    notifications.value = notifications.value.map((n) => {
      if (!n.read_at) {
        return {
          ...n,
          read_at: new Date().toISOString(),
        }
      }

      return n
    })
  }

  const removeStore = () => {
    notifications.value = []
    more.value = false
    unread.value = 0
    lastId.value = 0
  }

  const fetchNotifications = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__NOTIFICATION_LAST']
    >(API.GET__NOTIFICATION_LAST)

    const rawData = unref(data)

    if (!error.value && rawData) {
      return initStore(rawData)
    }
    logError(
      'from actions',
      'There was a problem fetching notifications: ',
      error,
    )
  }

  const { authenticated } = useAuthenticated()

  const fetchUnreadCount = async () => {
    if (!authenticated.value) return
    const { data, error } = await useCustomFetch<
      Endpoints['GET__NOTIFICATION_COUNT']
    >(API.GET__NOTIFICATION_COUNT)

    const rawData = unref(data)
    if (!error.value && rawData) {
      setCount(rawData)
    }

    logError(
      'from actions',
      'There was a problem fetching unread count notifications: ',
      error,
    )
  }

  const loadNewerNotifications = async () => {
    if (!notifications.value.length) {
      await fetchNotifications()
      return
    }
    const { data, error } = await useCustomFetch<
      Endpoints['GET__NOTIFICATION_LAST']
    >(API.GET__NOTIFICATION_LAST)

    const rawData = unref(data)

    if (!error.value && rawData) {
      return setNotifications(rawData)
    }

    logError(
      'from actions',
      'There was a problem fetching newer notifications: ',
      error,
    )
  }

  const loadMoreNotifications = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__NOTIFICATION_LAST']
    >(API.GET__NOTIFICATION_LAST, {
      query: {
        id: lastId.value,
      },
    })

    const rawData = unref(data)

    if (!error.value && rawData) {
      return addNotifications(rawData)
    }

    logError(
      'from actions',
      'There was a problem fetching more notifications: ',
      error,
    )
  }

  const readNotification = async (notification: CustomNotification) => {
    if (notification.read_at === null) {
      const { data, error } = await useCustomFetch<
        Endpoints['PUT__NOTIFICATION_READ']
      >(API.PUT__NOTIFICATION_READ.replace('$id', notification.id.toString()), {
        method: 'PUT',
      })

      const rawData = unref(data)

      if (!error.value && rawData) {
        return setUnread(notification, rawData)
      }

      logError(
        'from actions',
        'There was a problem reading notification: ',
        error,
      )
    }
  }

  const readAllNotifications = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['PUT__NOTIFICATION_READ_ALL']
    >(API.PUT__NOTIFICATION_READ_ALL, { method: 'PUT' })

    const rawData = unref(data)

    if (!error.value && rawData) {
      return setUnreadAll(rawData)
    }

    logError(
      'from actions',
      'There was a problem reading all notifications: ',
      error,
    )
  }

  const removeNotifications = async () => {
    removeStore
  }

  return {
    // STATE
    notifications,
    more,
    unread,
    lastId,

    // ACTIONS
    fetchNotifications,
    fetchUnreadCount,
    loadNewerNotifications,
    loadMoreNotifications,
    readNotification,
    readAllNotifications,
    removeNotifications,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationsStore, import.meta.hot),
  )
}
