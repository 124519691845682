import type { Endpoints, GameTypeWithRoute } from '~/types'

export const useUniversesStore = defineStore('universesStore', () => {
  const universes = ref<GameTypeWithRoute[]>([])
  const otherUniverses = ref<GameTypeWithRoute[]>([])
  const currentUniverse = ref<Endpoints['GET__GAME_TYPE'][number]>()

  const fetchUniverses = async () => {
    const { data, error } = await useCustomFetch<Endpoints['GET__GAME_TYPE']>(
      API.GET__GAME_TYPE,
    )

    if (!error.value && data.value) {
      const formattedData = data.value.map((item) => ({
        ...item,
        route: `/communautes/genre/${item.slug}`,
      }))

      universes.value = formattedData
    } else {
      console.error(
        'from actions',
        'There was a problem fetching universes: ',
        error,
      )

      universes.value = []
    }
  }

  const fetchOtherUniverses = async (gender: string) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__GAME_TYPE']>(
      API.GET__GAME_TYPE,
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      otherUniverses.value = []
      return logError(
        'from actions',
        'There was a problem fetching other universes: ',
        error,
      )
    }

    const formattedData: GameTypeWithRoute[] = []
    rawData.forEach((element) => {
      if (element.slug === gender) {
        currentUniverse.value = { ...element }
      } else {
        formattedData.push({
          ...element,
          route: `/communautes/genre/${element.slug}`,
        })
      }
    })

    otherUniverses.value = formattedData
  }

  return {
    // State
    universes,
    otherUniverses,
    currentUniverse,

    // Actions
    fetchUniverses,
    fetchOtherUniverses,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUniversesStore, import.meta.hot))
}
