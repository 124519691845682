import validate from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirect_45global from "/home/web/wap/_online/vue_src/nuxt/middleware/01.redirect.global.ts";
import _02_45fetch_45global from "/home/web/wap/_online/vue_src/nuxt/middleware/02.fetch.global.ts";
import _03_45temp_45user_45global from "/home/web/wap/_online/vue_src/nuxt/middleware/03.temp-user.global.ts";
import trailing_45slash_45redirect_45global from "/home/web/wap/_online/vue_src/nuxt/middleware/trailing-slash-redirect.global.ts";
import manifest_45route_45rule from "/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.9_eslint@8.57.0_ioredis@5.4.1_magicast@0._onmdclvzxkht5nebzpuvdmum54/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirect_45global,
  _02_45fetch_45global,
  _03_45temp_45user_45global,
  trailing_45slash_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "temp-auth": () => import("/home/web/wap/_online/vue_src/nuxt/middleware/temp-auth.ts"),
  auth: () => import("/home/web/wap/_online/vue_src/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+core@7.24.9_reac_ps2glc62svxvlwipgratgnvyom/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}