import type {
  ArticleWithRoute,
  CustomArticle2WithRoute,
  Endpoints,
} from '~/types'
import { API } from '~/utils/constants'

export const useActualitiesWAPStore = defineStore('actualitiesWAPStore', () => {
  const socialStore = useSocialStore()

  const actualities = ref<ArticleWithRoute[]>([])
  const actualitiesPages = ref(1)
  const actualitiesCurrentPage = ref(1)
  const actuality = ref<CustomArticle2WithRoute>()
  const bannedIds = ref<number[]>([])

  const getBannedIds = computed(() => bannedIds.value)
  const getPagesCount = computed(() => actualitiesPages.value)
  const getCurrentPage = computed(() => actualitiesCurrentPage.value)

  const setActualities = (actualityItems: ArticleWithRoute[]) => {
    actualities.value = actualityItems
  }

  const setActualitiesPages = (pages: number) => {
    actualitiesPages.value = pages
  }

  const setActualitiesCurrentPage = (page: number) => {
    actualitiesCurrentPage.value = page
  }

  const setActuality = (actualityItem?: CustomArticle2WithRoute) => {
    actuality.value = actualityItem
  }

  const setBannedIds = (ids: number[]) => {
    bannedIds.value = ids
  }

  const fetchActualities = async (
    { page = 1, limit = 36 } = { page: 1, limit: 36 },
  ) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__ARTICLE_SEARCH']
    >(API.GET__ARTICLE_SEARCH, {
      query: {
        page,
        limit,
      },
    })

    const rawData = unref(data)
    if (!error.value && rawData) {
      const formattedData = rawData.results.map((element) => ({
        ...element,
        route: `/actualites-playstation/${element.slug}`,
        type: 'actuality',
        user: element.contributor,
      }))

      setActualities(formattedData)
      setActualitiesPages(rawData.pages_count)
      setActualitiesCurrentPage(page)
    } else {
      setActualities([])
      logError(
        'from actions',
        'There was a problem fetching PlayStation actualities: ',
        error,
      )
    }
  }

  const fetchOtherActualities = async ({ page = 1 } = { page: 1 }) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__ARTICLE_SEARCH']
    >(API.GET__ARTICLE_SEARCH, {
      query: {
        page,
        banned_ids: bannedIds.value,
      },
    })
    const rawData = unref(data)

    if (!error.value && rawData) {
      const formattedData = rawData.results.map((element) => ({
        ...element,
        route: `/actualites-playstation/${element.slug}`,
      }))

      setActualities(formattedData)
      setActualitiesPages(rawData.pages_count)
    } else {
      setActualities([])
      logError(
        'from actions',
        'There was a problem fetching other PlayStation actualities: ',
        error,
      )
    }
  }

  const { authenticated } = useAuthenticated()
  const fetchActuality = async ({ slug }: { slug: string }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__ARTICLE']>(
      `${authenticated.value ? '/secure' : ''}${API.GET__ARTICLE}/${slug}`,
    )
    const rawData = unref(data)

    if (error.value || !rawData) {
      setActuality()
      logError(
        'from actions',
        'There was a problem fetching actuality: ',
        error,
      )
      throw error.value
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    const formattedData = {
      ...rawData,
      route: `/actualites-playstation/${rawData.slug}`,
    }

    socialStore.fetchSocial({
      slug,
      slugType: 'actu-play',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
      is_following: rawData.is_following ?? false,
    })

    setActuality(formattedData)
    if (rawData.id) setBannedIds([rawData.id])
  }

  return {
    // State
    actualities,
    actualitiesPages,
    actualitiesCurrentPage,
    actuality,
    bannedIds,

    // Getters
    getBannedIds,
    getPagesCount,
    getCurrentPage,

    // Mutations
    setActualities,
    setActualitiesPages,
    setActualitiesCurrentPage,
    setActuality,
    setBannedIds,

    // Actions
    fetchActualities,
    fetchOtherActualities,
    fetchActuality,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useActualitiesWAPStore, import.meta.hot),
  )
}
