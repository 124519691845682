import type { Endpoints, FormattedReferential } from '~/types'

export const useReferentialStore = defineStore('useReferentialStore', () => {
  // STATE
  const referential = ref<FormattedReferential>()

  // ACTIONS
  const fetchReferential = async () => {
    if (referential.value) return

    const { data, error } = await useCustomFetch<Endpoints['GET__REFERENTIAL']>(
      `${API.GET__REFERENTIAL}`,
    )

    const rawData = unref(data)

    if (error.value || !rawData) {
      referential.value = undefined

      return logError(
        'from actions',
        'There was a problem fetching referential: ',
        error,
      )
    }
    const { bvReviews, consoles, services, points } = rawData

    const dataValues: FormattedReferential = {
      services,
      platforms: consoles,
      points,
      hoursPlayed: [],
      choiceMarketPlace: [],
      choiceRangeAge: [],
      choiceGender: [],
      choiceYesNo: [],
      choicePsPlus: [],
    }

    Object.entries(bvReviews.hoursPlayed).forEach(([index, value]) => {
      switch (index) {
        case '1': {
          dataValues.hoursPlayed.push({
            value,
            label: 'Moins de 5 heures',
          })
          break
        }
        case '2': {
          dataValues.hoursPlayed.push({
            value,
            label: 'De 5 à 10 heures',
          })
          break
        }
        case '3': {
          dataValues.hoursPlayed.push({
            value,
            label: 'De 10 à 15 heures',
          })
          break
        }
        case '4': {
          dataValues.hoursPlayed.push({
            value,
            label: 'Plus de 15 heures',
          })
          break
        }
      }
    })

    Object.entries(bvReviews.choiceMarketPlace).forEach(([index, value]) => {
      switch (index) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '10':
        case '11':
        case '16':
          dataValues.choiceMarketPlace.push({
            value,
            label: value,
          })
          break
        case '12':
          dataValues.choiceMarketPlace.push({
            value,
            label: 'Jeux offerts PS+',
          })
          break
        case '13':
          dataValues.choiceMarketPlace.push({
            value,
            label: 'Rue Du Commerce',
          })
          break
        case '14':
          dataValues.choiceMarketPlace.push({
            value,
            label: 'PlayStation Store',
          })
          break
        case '15':
          dataValues.choiceMarketPlace.push({
            value,
            label: "Toys'R'Us",
          })
          break
      }
    })

    Object.entries(bvReviews.choiceRangeAge).forEach(([index, value]) => {
      switch (index) {
        case '1': {
          dataValues.choiceRangeAge.push({
            value,
            label: 'Moins de 17 ans',
            range: [0, 17],
          })
          break
        }
        case '2':
          dataValues.choiceRangeAge.push({
            value,
            label: 'De 18 à 24 ans',
            range: [18, 24],
          })
          break
        case '3':
          dataValues.choiceRangeAge.push({
            value,
            label: 'De 25 à 34 ans',
            range: [25, 34],
          })
          break
        case '4':
          dataValues.choiceRangeAge.push({
            value,
            label: 'De 35 à 44 ans',
            range: [35, 44],
          })
          break
        case '5':
          dataValues.choiceRangeAge.push({
            value,
            label: 'De 45 à 54 ans',
            range: [45, 54],
          })
          break
        case '6':
          dataValues.choiceRangeAge.push({
            value,
            label: 'De 55 à 64 ans',
            range: [55, 64],
          })
          break
        case '7':
          dataValues.choiceRangeAge.push({
            value,
            label: 'Plus de 65 ans',
            range: [65, 150],
          })
          break
      }
    })

    Object.entries(bvReviews.choiceGender).forEach(([index, value]) => {
      switch (index) {
        case '1': {
          dataValues.choiceGender.push({
            value,
            label: 'Monsieur',
          })
          break
        }
        case '2':
          dataValues.choiceGender.push({
            value,
            label: 'Madame',
          })
          break
      }
    })

    bvReviews.choiceYesNo.forEach((item) => {
      dataValues.choiceYesNo.push({
        value: item,
        label: item,
      })
    })

    referential.value = dataValues
  }

  return {
    // STATE
    referential,

    // ACTIONS
    fetchReferential,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReferentialStore, import.meta.hot))
}
